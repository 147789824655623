<div class="modal-body text-center bg-primary">
    <span class=" text-white m-auto">Seleziona da quale punto <b>{{projectname}}</b> stai operando</span>
</div>
<div class="modal-body bg-light" *ngIf="list!=null && list.length>0">
    <ul class="list-group" *ngFor="let r of list">
        <li class="list-group-item mb-1" (click)="confirm(r)" style="cursor:pointer;">
            <div class="leaflet-marker-icon extra-marker extra-marker-circle-blue-dark ml-2"><i class="initials"></i></div>
            <div class="ml-5">
                <div style="max-width:80%;vertical-align: middle;" class="float-left text-truncate">
                    <div *ngIf="!r.city" class="py-1"></div>
                    <b class="text-primary">{{r.name}}</b><br>
                    <small *ngIf="r.city">{{r.city}}</small></div>
                <div class="float-right">
                    <h3><i class="text-light fas fa-angle-right"></i></h3>
                </div>
                <br>
            </div>
        </li>
    </ul>
</div>

