<app-windowlist [showCard]="showCard" [model]="this">
    
    <div class="row my-1">
        <div class="col-lg-2 pr-lg-0" *ngIf="filterbox">
            <div class="card card-outline mb-0 p-1 d-block d-lg-none">
                <span (click)="filter_typedocument.value='';getItems()" style="cursor:pointer;" class="badge bg-gray mr-1" >TUTTE</span>
                <span (click)="setFilterTypeValue('filter_typedocument',1);setFilterTypeValue('filter_typedocument',2);setFilterTypeValue('filter_typedocument',12)" style="cursor:pointer;" class="badge bg-purple mr-1">FS</span>
                <span (click)="setFilterTypeValue('filter_typedocument',1)" style="cursor:pointer;" class="badge bg-teal mr-1" >FA</span>
                <span (click)="setFilterTypeValue('filter_typedocument',12)" style="cursor:pointer;" class="badge bg-teal mr-1" >FAc</span>
                <span (click)="setFilterTypeValue('filter_typedocument',3)" style="cursor:pointer;" class="badge bg-pink mr-1">NC</span>
                <span (click)="setFilterTypeValue('filter_typedocument',4)" style="cursor:pointer;" class="badge badge-warning mr-1">PR</span>
                <span (click)="setFilterTypeValue('filter_typedocument',5)" style="cursor:pointer;" class="badge badge-secondary mr-1">DDT</span>
                <!-- <span (click)="setFilterTypeValue('filter_typedocument',10)" style="cursor:pointer;" class="badge badge-secondary">TR</span> -->
                <span (click)="setFilterTypeValue('filter_typedocument',6)" style="cursor:pointer;" class="badge badge-secondary mr-1">SC</span>
                <span (click)="setFilterTypeValue('filter_typedocument',7)" style="cursor:pointer;" class="badge badge-secondary mr-1">OC</span>
                <span (click)="setFilterTypeValue('filter_typedocument',8)" style="cursor:pointer;" class="badge badge-secondary mr-1">OF</span>
                <span (click)="setFilterTypeValue('filter_typedocument',9)" style="cursor:pointer;" class="badge badge-secondary mr-1">NF</span>
                <span (click)="setFilterTypeValue('filter_typedocument',11)" style="cursor:pointer;" class="badge bg-olive mr-1">DA</span>
            </div>
            <small>
                <ul class="card small-box list-group treeviewlist p-1 d-none d-lg-block">
                    <li class="list-group-item" [class.active]="filter_typedocument.value==''" (click)="filter_typedocument.value='';getItems()" style="cursor:pointer;">Tutte le tipologie</li>
                    <li class="list-group-item"  (click)="setFilterTypeValue('filter_typedocument',1);setFilterTypeValue('filter_typedocument',2);setFilterTypeValue('filter_typedocument',12)" style="cursor:pointer;">Fattura</li>
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',1)" (click)="setFilterTypeValue('filter_typedocument',1)" style="cursor:pointer;">-- Fattura semplice</li>
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',2)" (click)="setFilterTypeValue('filter_typedocument',2)" style="cursor:pointer;">-- Fattura accompagnatoria</li>
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',12)" (click)="setFilterTypeValue('filter_typedocument',12)" style="cursor:pointer;">-- Fattura acconto</li>
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',3)" (click)="setFilterTypeValue('filter_typedocument',3)" style="cursor:pointer;">Nota di credito</li>
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',4)" (click)="setFilterTypeValue('filter_typedocument',4)" style="cursor:pointer;">Preventivo</li>
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',5)" (click)="setFilterTypeValue('filter_typedocument',5)" style="cursor:pointer;">DDT</li>
                    <!--<li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',10)" (click)="setFilterTypeValue('filter_typedocument',10)" style="cursor:pointer;">Trasferimento</li>-->
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',6)" (click)="setFilterTypeValue('filter_typedocument',6)" style="cursor:pointer;">Scontrino</li>
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',7)" (click)="setFilterTypeValue('filter_typedocument',7)" style="cursor:pointer;">Ordine Cliente</li>
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',8)" (click)="setFilterTypeValue('filter_typedocument',8)" style="cursor:pointer;">Ordine Fornitore</li>
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',9)" (click)="setFilterTypeValue('filter_typedocument',9)" style="cursor:pointer;">Non fiscale</li>
                    <li class="list-group-item" [class.active]="checkFilterTypeValue('filter_typedocument',11)" (click)="setFilterTypeValue('filter_typedocument',11)" style="cursor:pointer;">Amministrativo</li>
                    <div class="icon"><i class="fa fa-file-invoice-dollar "></i></div>
                </ul>
                <app-filterdate [date_type]="mode!='embedded'?1:100" [class]="'card treeviewlist p-1 mt-lg-2'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>
                <ul class="card small-box list-group treeviewlist p-1 mt-lg-2 mb-4 d-none d-lg-block" *ngIf="user.isAdmin()">
                    <li class="list-group-item" [class.active]="filter_created_from.value==''" (click)="filter_created_from.value='';getItems()" style="cursor:pointer;">Tutti gli esercizi</li>
                    <li class="list-group-item" *ngFor="let s of shops" [class.active]="filter_created_from.value==s.id" (click)="filter_created_from.value=s.id;getItems()" style="cursor:pointer;">{{s.name}}</li>
                    <div *ngIf="shops.length>2" class="icon"><i class="fas fa-store-alt "></i></div>
                </ul>
                <div class="card card-outline mb-0 p-1 d-block d-lg-none">
                    <div class="input-group input-group-sm">
                        <div class="input-group-prepend input-group-prepend-sm">
                            <span class="input-group-text"><i class="fas fa-store-alt"></i></span>
                        </div>
                        <select class="form-control form-control-sm" name="filter_created_from" [(ngModel)]="filter_created_from.value" (change)="getItems()">
                            <option value="">Tutti gli esercizi</option>
                            <option *ngFor="let s of shops" value="s.id">{{s.name}}</option>
                        </select>
                    </div>
                </div>
            </small>

        </div>
        <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" >
            <div class="card card-outline">
                <div class="p-0">
                    <ng-container *ngIf="filterbox">
                        <div class="row p-1" >
                            <div class="col-7 col-lg-9">
                                <div class="input-group input-group-info input-group-sm">
                                    <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="nome cliente o descrizione" [(ngModel)]="filter_search.value" (keyup.enter)="onSearchEnter()">
                                    <input type="text" class="form-control form-control-sm" placeholder="numero documento" style="max-width:150px;" [(ngModel)]="filter_reference.value" (keyup.enter)="onSearchEnter()">
                                    <div class="input-group-append ">
                                        <button type="button" class="btn btn-default" data-toggle="tooltip" title="avvia ricerca" (click)="onSearchEnter()"><i class="fas fa-search"></i></button>
                                        <button class="btn btn-default text-danger" *ngIf="filter_search.value!='' || filter_reference.value!=''" data-toggle="tooltip" title="annulla ricerca" (click)="filter_search.value='';filter_reference.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5 col-lg-3 py-1">
                                <div class="dropdown dropleft show"  *ngIf="user.isAdmin()">
                                    <a class="btn btn-default btn-xs float-right ml-1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" title="Altre operazioni" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-ellipsis-v"></i>
                                    </a>
                                    <div class="dropdown-menu py-0 bg-light" aria-labelledby="dropdownMenuLink">
                                        <small class="dropdown-item p-1 bg-gray"><b> SDI</b></small>
                                        <a type="button" *ngIf="type==1"  class="btn btn-xs dropdown-item" (click)="sendMultipleEInvoice()">Invia a SDI</a>
                                        <a type="button" *ngIf="user.isSuperUser() && type==1"  class="btn btn-xs dropdown-item"  (click)="checkAllInvoices()">Verifica stato SDI documenti inviati</a>
                                        <a type="button" *ngIf="type==1"  class="btn btn-xs dropdown-item"  (click)="checkStatusEInvoice()">Verifica stato SDI documenti non consegnati</a>
                                        <a type="button" *ngIf="type==2"  class="btn btn-xs dropdown-item" (click)="checkDocumentSdi()">Verifica presenza documenti in ingresso su SDI</a>
                                        <a type="button" *ngIf="type==2"  class="btn btn-xs dropdown-item" (click)="uploadXML()">Carica documento di ingresso (XML)</a>
                                        <a type="button" *ngIf="user.isSuperUser()"  class="btn btn-xs dropdown-item" (click)="resaveDownloadedFileAllDocuments()">Scarica tutti i file da SDI</a>
                                        <small class="dropdown-item p-1 bg-gray"><b>ALTRO</b></small>
                                        <a type="button" *ngIf="type==1"  class="btn btn-xs dropdown-item" (click)="openReorderNumeration()">Riordina la numerazione dei documenti</a>
                                        <a type="button" class="btn btn-xs dropdown-item" (click)="mergeDocuments()">Unisci documenti</a>
                                    </div>
                                </div>
                                <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="far fa-trash-alt"></i></button>
                                <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="fas fa-trash"></i></button>
                                <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-undo"></i></button>
                                <button  (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-file-export"></i></button>
                                <button (click)="openDetail(null,{'name':'id_type','value':type})"  class="btn btn-success btn-xs float-right ml-1"><i class="fas fa-plus"></i></button>
                            </div>
                        </div>
                        <small>
                            <div class="small-box bg-light m-1 p-1">
                                <div class="row mb-1">
                                    <div class="d-none d-lg-block col-lg-2 text-lg-right p-2">Metodo di pagamento</div>
                                    <div class="col-6 col-lg-4">
                                        <select class="form-control form-control-sm p-1"  [(ngModel)]="filter_method_payment.value" (ngModelChange)="getItems()" [class.alert-success]="filter_method_payment.value">
                                            <option value="">Tutti</option>
                                            <option *ngFor="let m of methodpayments" (value)="m.name">{{m.name}}</option>
                                        </select>
                                    </div>
                                    <div class="d-none d-lg-block col-lg-2 text-lg-right p-2">Stato documento</div>
                                    <div class="col-6 col-lg-4">
                                        <select class="form-control form-control-sm p-1" [(ngModel)]="this.filter_state.value" (change)="getItems()" 
                                        [class.alert-warning]="this.filter_state.value==1" 
                                        [class.alert-success]="this.filter_state.value==2" 
                                        [class.alert-danger]="this.filter_state.value==3" 
                                        [class.alert-info]="this.filter_state.value==4 || this.filter_state.value==5 || this.filter_state.value==6" >
                                            <option value="0">Tutti</option>
                                            <option value="1">Da confermare</option>
                                            <option value="2">Confermato</option>
                                            <option value="3">Annullato</option>
                                            <option value="4">Ordinato</option>
                                            <option value="5">Spedito</option>
                                            <option value="6">Parziale</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="d-none d-lg-block col-lg-2 text-lg-right p-2">Sezionale</div>
                                    <div class="col-6 col-lg-4">
                                        <select class="form-control form-control-sm p-1" [(ngModel)]="this.filter_referenceprefix.value" (change)="getItems()" [class.alert-info]="this.filter_referenceprefix.value">
                                            <option value="">Tutti</option>
                                            <option *ngFor="let rp of referenceprefix" value="{{rp.code}}">{{rp.code}} ({{rp.label}})</option>
                                        </select>
                                    </div>
                                    <div class="d-none d-lg-block col-lg-2 text-lg-right p-2">Stato SDI</div>
                                    <div class="col-6 col-lg-4">
                                        <select class="form-control form-control-sm p-1" [(ngModel)]="this.filter_sdi_status.value" (change)="getItems()" 
                                        [class.alert-info]="this.filter_sdi_status.value==-1"
                                        [class.alert-warning]="this.filter_sdi_status.value==1"
                                        [class.alert-success]="this.filter_sdi_status.value==2 || this.filter_sdi_status.value==4"
                                        [class.alert-danger]="this.filter_sdi_status.value==3">
                                            <option value="">Tutti</option>
                                            <option value="-1">Da inviare</option>
                                            <option value="1">Inviata</option>
                                            <option value="2">Consegnata</option>
                                            <option value="3">Errore</option>
                                            <option value="4">Confermata ma con mancata consegna</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-1">
                                    <div class="d-none d-lg-block col-lg-2 text-lg-right p-2">Tipologia</div>
                                    <div class="col-6 col-lg-4">
                                        <select class="form-control form-control-sm p-1" [(ngModel)]="this.filter_nation.mode" (change)="getItems()" [class.alert-info]="this.filter_nation.mode">
                                            <option value="0">Tutti</option>
                                            <option value="0">Italiane</option>
                                            <option value="14">Estere</option>
                                            
                                        </select>
                                    </div>
                              
                                    <div class="d-none d-lg-block col-lg-2 text-lg-right p-2">Stato pagamento</div>
                                    <div class="col-6 col-lg-4">
                                        <select class="form-control form-control-sm p-1" [(ngModel)]="filter_state_payment.enabled" [class.alert-danger]="filter_state_payment.enabled" (change)="getItems()" >
                                            <option [ngValue]="false">Tutti</option>
                                            <option [ngValue]="true">Da pagare</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-1" *ngIf="isModuleEnabled('financialaccounts')">
                                    <div class="d-none d-lg-block col-lg-2 text-lg-right p-2">Conti contabili</div>
                                    <div class="col-12 col-lg-4">
                                        <app-financialaccount [showAll]="true" [id_financialaccount]="this.filter_financialaccount.value" (selected)="this.filter_financialaccount.value=$event['id'];getItems()"></app-financialaccount>
                                    </div>
                                </div>
                                <div class="icon" style="right:calc(100% - 100px)"><i class="fas fa-microscope"></i></div>
                            </div>
                        </small>
                    </ng-container>

                    <div *ngIf="list==null" class="m-4">
                        <i class="fa-2x fas fa-info text-info mr-3"></i>
                        <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
                    </div>
                
                    <form [formGroup]="form" *ngIf="list!=null">
                        <table class="table table-nowrap table-sm table-striped table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th width="20px">
                                    <input #checkboxselectall type="checkbox"
                                    (change)="onChangeAll($event.target.checked)" />
                                </th>
                                <th>Tipo</th>
                                <th>Data
                                    <button type="button" class="btn btn-tool px-1" (click)="switchOrdering('i.date')">
                                        <i class="fas fa-sort {{classOrdering('i.date')}}"></i>
                                    </button>
                                </th>
                                <th>Rif.
                                    <button type="button" class="btn btn-tool px-1" (click)="switchOrdering('CAST(i.reference AS UNSIGNED)')">
                                        <i class="fas fa-sort {{classOrdering('i.reference')}}"></i>
                                    </button>
                                </th>
                                <th *ngIf="id_address==0">
                                    <span *ngIf="type==1">Cliente</span>
                                    <span *ngIf="type==2">Fornitore</span>
                                    <button type="button" class="btn btn-tool px-1" (click)="switchOrdering('i.name')">
                                        <i class="fas fa-sort {{classOrdering('i.name')}}"></i>
                                    </button>
                                </th>
                                <th data-toggle="tooltip" title="Conti contabili" *ngIf="isModuleEnabled('financialaccounts')">Conti cont.</th>
                                <th>Totale</th>
                                <th>Pagato</th>
                                <th>Da pagare</th>
                                <th>Stato</th>
                                <th>Emesso</th>
                                <th></th>
                            </thead>
                            <tbody>
                            
                                <!-- elemento ripetuto -->
                                <ng-container *ngFor="let record of list">
                                    <tr [class.bg-darker]="record.showReference">
                                        <td>
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}" data-toggle="tooltip" title="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                        </td>
                                        <td>
                                            <span *ngIf="record.typedocument==1" data-toggle="tooltip" title="Fattura Semplice" class="badge bg-purple">FS</span>
                                            <span *ngIf="record.typedocument==2" data-toggle="tooltip" title="Fattura Accompagnatoria" class="badge bg-teal" >FA</span>
                                            <span *ngIf="record.typedocument==12" data-toggle="tooltip" title="Fattura Acconto" class="badge bg-teal" >FAc</span>
                                            <span *ngIf="record.typedocument==3" data-toggle="tooltip" title="Nota di Credito" class="badge bg-pink">NC</span>
                                            <span *ngIf="record.typedocument==4" data-toggle="tooltip" title="Preventivo" class="badge badge-warning">PR</span>
                                            <span *ngIf="record.typedocument==5" data-toggle="tooltip" title="Documento di trasporto" class="badge badge-secondary">DDT</span>
                                            <span *ngIf="record.typedocument==10" data-toggle="tooltip" title="Trasferimento" class="badge badge-secondary">TR</span>
                                            <span *ngIf="record.typedocument==6" data-toggle="tooltip" title="Scontrino" class="badge badge-secondary">SC</span>
                                            <span *ngIf="record.typedocument==7" data-toggle="tooltip" title="Ordine cliente" class="badge badge-secondary">OC</span>
                                            <span *ngIf="record.typedocument==8" data-toggle="tooltip" title="Ordine fornitore" class="badge badge-secondary">OF</span>
                                            <span *ngIf="record.typedocument==9" data-toggle="tooltip" title="Non fiscale" class="badge badge-secondary">NF</span>
                                            <span *ngIf="record.typedocument==11" data-toggle="tooltip" title="Documento amministrativo" class="badge bg-olive">DA</span>
                                            <small>
                                                <i *ngIf="record.islinked" class="fas fa-link ml-1"></i>
                                            </small>
                                            <br/>
                                            <small>
                                                <span *ngIf="record.private">
                                                    <container-element [ngSwitch]="record.private">
                                                        <some-element *ngSwitchCase="1">Privato</some-element>
                                                        <some-element *ngSwitchCase="2">Estera</some-element>
                                                        <some-element *ngSwitchDefault></some-element>
                                                    </container-element>
                                                </span>
                                            </small>
                                            <small *ngIf="type==0">
                                                <span *ngIf="record.id_type==2">in</span>
                                                <span *ngIf="record.id_type==1">out</span>
                                            </small>
                                        </td>
                                        <td>
                                            <a [class.trashed]="record.status == 2" (dblclick)="record.showReference=true"  data-toggle="tooltip" title="Doppio click per modifica rapida" style="cursor:pointer;">
                                                {{record.date | date:'dd/M/yyyy'}}<br><small class="text-gray">{{record.date | date:'EEEE'}}</small>
                                            </a>
                                        </td>
                                        <td>
                                            <span  data-toggle="tooltip" title="Doppio click per modifica rapida" style="cursor:pointer;">{{ record.reference }}</span>
                                            <span *ngIf="record.referenceprefix!=''">/{{ record.referenceprefix }}</span>
                                        </td>
                                        <td *ngIf="id_address==0" style="max-width:20rem">
                                            <ng-container *ngIf="record.addressItem">{{record.addressItem.name}}</ng-container>
                                            <span *ngIf="(record.typedocument==1 || record.typedocument==2 || record.typedocument==12 || record.typedocument==3) && record.id_type==1">
                                                <i data-toggle="tooltip" title="{{verifyBillingAddress(record)}}" class="text-warning fas fa-exclamation-triangle ml-2"
                                                *ngIf="verifyBillingAddress(record)!=''"></i>
                                            </span>
                                            <br/>
                                            <div *ngIf="record.typedocument==5 && record.private" class="border-top">
                                                <small>destinazione:</small> {{ record.name_destination }}
                                            </div>
                                            <hr class="my-0"/>
                                            <small  data-toggle="tooltip" title="{{record.description2}}"><i>{{record.description}}<span *ngIf="record.description2"> / {{record.description2}}</span></i></small>
                                            <ng-container *ngIf="record.note">
                                                <hr class="my-0"/>
                                                <small><i class="fa fa-exclamation mr-2"></i>{{record.note}}</small>
                                            </ng-container>
                                        </td>
                                        <td *ngIf="isModuleEnabled('financialaccounts')">
                                            <div style="max-width:6rem">
                                                <small>
                                                    <i (dblclick)="record['editFinancial']=true" class="fas fa-coins" style="cursor: pointer" data-toggle="tooltip" title="Doppio click per selezione conto contabile"></i>
                                                    {{record.financialaccount}}
                                                    <ng-container *ngIf="record['editFinancial']">
                                                        <app-financialaccount [id_financialaccount]="record.id_financialaccount" [showOnlyTree]="true" [financialaccount]="record.financialaccount" (selected)="updateFinancialAccount(record,$event);"></app-financialaccount>
                                                    </ng-container>
                                                
                                                </small>
                                            </div>
                                        </td>
                                        <td [class.text-red]="record.total < 0" [class.text-red]="record.typedocument==3">
                                            <ng-container *ngIf="record.typedocument!=5">
                                                <ng-container [ngSwitch]="record.typedocument">
                                                    <span *ngIf="typedocument_to_accounting(record); else total_not_accounting">
                                                        <ng-container *ngIf="type==0">
                                                            <span *ngIf="record.id_type==1">+</span>
                                                            <span *ngIf="record.id_type==2">-</span>
                                                        </ng-container>
                                                        {{ record.total | currency:'EUR':true }}
                                                    </span>
                                                    <ng-template #total_not_accounting>
                                                        <span data-toggle="tooltip" title="Importo non conteggiato nel totale">
                                                            (<ng-container *ngIf="type==0">
                                                                <span *ngIf="record.id_type==1">+</span>
                                                                <span *ngIf="record.id_type==2">-</span>
                                                            </ng-container>
                                                            {{ record.total | currency:'EUR':true }})
                                                        </span>
                                                    </ng-template>
                                                </ng-container>
                                                <br/>
                                                <small><strong>{{record.methodpayment}}</strong></small>
                                                <br/>
                                            </ng-container>
                                        </td>
                                        <td [class.text-success]="record.paid > 0">
                                            <span *ngIf="typedocument_to_accounting(record); else total_not_accounting">
                                                <ng-container *ngIf="type==0">
                                                    <span *ngIf="record.id_type==1">+</span>
                                                    <span *ngIf="record.id_type==2">-</span>
                                                </ng-container>   
                                                {{record.paid | currency:"&euro;"}}
                                            </span>
                                            <ng-template #total_not_accounting *ngIf="record.paid !=0 ">
                                                <span data-toggle="tooltip" title="Importo non conteggiato nel totale">(
                                                    <ng-container *ngIf="type==0">
                                                        <span *ngIf="record.id_type==1">+</span>
                                                        <span *ngIf="record.id_type==2">-</span>
                                                    </ng-container>
                                                    {{ record.paid | currency:'EUR':true }})</span>
                                            </ng-template>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="record.typedocument==1 || record.typedocument==2 || record.typedocument==12 || record.typedocument==3 || record.typedocument==6">
                                                <ng-container *ngIf="record.total!=record.paid">
                                                    <br/>
                                                    
                                                    <span *ngIf="record.paid-record.total>0" data-toggle="tooltip" title="Pagato in eccesso" class="text-warning">+ {{record.paid - record.total | currency:'EUR':true}}</span>
                                                    <span *ngIf="record.id_documentsrule!='4' && record.paid-record.total<0" (click)="addPayment(record);" data-toggle="tooltip" title="Apri scheda pagamento" class="text-danger" style="cursor:pointer;">{{record.paid - record.total | currency:'EUR':true}}</span>
                                                    <span *ngIf="record.id_documentsrule=='4' && record.paid<record.net" (click)="addPayment(record);" data-toggle="tooltip" title="Apri scheda pagamento" class="text-danger" style="cursor:pointer;">{{record.paid - record.net | currency:'EUR':true}}</span>
                                                </ng-container>
                                            </ng-container>  
                                        </td>
                                        <td>
                                            <app-statedocument [state]="record.state"></app-statedocument>
                                            <ng-container *ngIf="(record.typedocument==1 || record.typedocument==2 || record.typedocument==12|| record.typedocument==3) && record.id_type==1">
                                                <br/>
                                                <span class="badge bg-darker" >
                                                    SDI:&nbsp;
                                                    <i data-toggle="tooltip" title="Click per inviare" *ngIf="record.sdi_status==0 || record.sdi_status==-1" style="cursor: pointer;" class="text-info fas fa-paper-plane" (click)="sendEInvoice(record.id,record.typedocument)"></i>
                                                    <i data-toggle="tooltip" title="Inviata, in attesa di risposta" *ngIf="record.sdi_status==1" class="text-warning fa fa-clock"></i>
                                                    <i data-toggle="tooltip" title="Consegnata" *ngIf="record.sdi_status==2" class="text-success fa fa-check"></i>
                                                    <i data-toggle="tooltip" title="In errore" *ngIf="record.sdi_status==3" class="text-danger fa fa-exclamation"></i>
                                                    <i data-toggle="tooltip" title="Consegnata ma con mancato recapito" *ngIf="record.sdi_status==4" class="text-success fa fa-check"></i>
                                                </span>
                                            </ng-container>
                                        </td>
                                        <td style="font-size:80%">
                                            <div style="max-width:4rem">{{record.username}}</div>
                                            <div class="text-truncate" style="max-width:4rem"><small>{{record.shopname}}</small></div>
                                            <div style="max-width:4rem"><small><b>{{record.origin}}</b></small></div>
                                        </td>
                                        <td>
                                            <!--<button type="button" class="btn btn-sm btn-info" (click)="sendEInvoice(record.id)" *ngIf="(record.typedocument==1 || record.typedocument==2) && record.id_type==1 && record.sdi_status==0">SDI</button>-->
                                            <ng-container  *ngIf="mode!='modal'">
                                                <button class="btn btn-default btn-xs float-right ml-1" type="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-ellipsis-v"></i>
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                    <button type="button" class="btn btn-xs dropdown-item" data-toggle="tooltip" title="Converti" (click)="openConvert(record)"><i class="fas fa-retweet mr-1"></i>Converti</button>
                                                    <button type="button" class="btn btn-xs dropdown-item" data-toggle="tooltip" title="Duplica" (click)="duplicate(record)"><i class="fas fa-clone mr-1"></i>Duplica</button>
                                                    <button type="button" class="btn btn-xs dropdown-item" data-toggle="tooltip" (click)="notify(record);" title="Notifica"><i class="far fa-paper-plane mr-1"></i>Notifica</button>
                                                </div>
                                            </ng-container>
                                            <button type="button" *ngIf="mode!='modal'"  (click)="openDetail(record)" routerLinkActive="active" data-toggle="tooltip" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1">
                                                <i class="fas fa-pencil-alt"></i><span class="d-none d-sm-inline-block"></span></button>
                                            <button type="button" *ngIf="mode!='modal'"  (click)="exportInRow(record)"  data-toggle="tooltip" title="Stampa" class="btn btn-warning btn-xs float-right ml-1">
                                                <i class="fas fa-print"></i><span class="d-none d-sm-inline-block"></span></button>
                                            <button type="button" *ngIf="mode!='modal' && record.files.length>0"  (click)="openlistFileModal(record)" data-toggle="tooltip" title="Mostra file allegati" class="btn btn-info btn-xs float-right ml-1">
                                                <i class="fas fa-file"></i><span class="d-none d-sm-inline-block"></span></button>
                                            <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right ml-1" (click)="selectRecord(record);">
                                                <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                                            </button>

                                            <button type="button"  *ngIf="mode=='embedded'" class="btn btn-danger btn-xs float-right ml-1" (click)="trash([record.id],true);">
                                                <i class="fas fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr *ngIf="record.showReference" class="bg-darker">
                                        <td colspan="3">
                                            <select  class="form-control form-control-sm" [(ngModel)]="record.typedocument" name="typedocument" #type="ngModel">
                                                <option value="1">Fattura semplice</option>
                                                <option value="2">Fattura accompagnatoria</option>
                                                <option value="12">Fattura acconto</option>
                                                <option value="3">Nota di credito</option>
                                                <option value="4">Preventivo</option>
                                                <option value="5">DDT</option>
                                                <option value="6">Scontrino</option>
                                                <option value="7">Ordine cliente</option>
                                                <option value="8">Ordine fornitore</option>
                                                <option value="9">Non fiscale</option>
                                                <option value="11">Documento amministrativo</option>
                                            </select>
                                        </td>
                                        <td colspan="2">
                                            <div class="input-group input-group-sm">
                                                <input type="text"  class="form-control form-control-sm" [(ngModel)]="record.reference">
                                                <select class="form-control form-control-sm p-1" [(ngModel)]="record.referenceprefix">
                                                    <option *ngFor="let rp of referenceprefix" value="{{rp.code}}">{{rp.code}} ({{rp.label}})</option>
                                                </select>
                                            </div>
                                        </td>
                                        <td colspan="2">
                                            <div class="input-group input-group-sm">
                                                <input type="date"  class="form-control form-control-sm" [(ngModel)]="record.date">
                                            </div>
                                        </td>
                                        <td colspan="5">
                                            <button class="btn btn-success btn-sm" (click)="update(record);record.showReference=false;"><i class="fa fa-check"></i></button>
                                            <button class="btn btn-danger btn-sm" (click)="record.showReference=false;getItems()"><i class="fa fa-times"></i></button>
                                        </td>
                                    </tr>
                                </ng-container>
                                <!-- FINE elemento ripetuto -->

                            </tbody>
                            <tfoot>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td><b>TOTALE</b></td>
                                    <td></td>
                                    <td *ngIf="id_address==0"></td>
                                    <td></td>
                                    <td [class.text-red]="total < 0"><b>{{total | currency:'EUR':true }}</b></td>
                                    <td [class.text-red]="paid < 0" [class.text-success]="paid > 0"><b>{{paid | currency:'EUR':true }}</b></td>                                    
                                    <td [class.text-red]="total-paid>0"><b>{{total-paid | currency:'EUR':true }}</b></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>


<ng-template #convertDocumentModal let-modal>
    <div class="modal-header bg-light py-1">
        <b>Converti documento</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="modal.close()" >
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Tipo documento</label>
            <select class="form-control" [(ngModel)]="convertTo">
                <option value="1">Fattura semplice</option>
                <option value="2">Fattura accompagnatoria</option>
                <option value="3">Nota di credito</option>
                <option value="4">Preventivo</option>
                <option value="5">DDT</option>
            </select>
        </div>
        <div class="form-group">
            <label>Sezionale</label>
            <select class="form-control form-control-sm p-1" [(ngModel)]="referenceprefixTo">
                <option value=""></option>
                <option *ngFor="let rp of referenceprefix" value="{{rp.code}}">{{rp.code}} ({{rp.label}})</option>
            </select>
        </div>

        <div class="form-group">
            <label>Tipologia</label>
            <select class="form-control form-control-sm p-1" [(ngModel)]="typeTo">
                <option [ngValue]="2">Ingresso</option>
                <option [ngValue]="1">Uscita</option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="convertDocument()">Converti</button>
        <button class="btn btn-secondary" (click)="modal.close()">Annulla</button>
    </div>
</ng-template>

<ng-template #reorderReferenceModel let-modal>
    <div class="modal-header bg-light py-1">
        <b>Rinumerazione documenti</b>
    </div>
    <div class="modal-body" style="overflow-y: auto;max-height: 400px;">
        <ng-container *ngIf="reorderTab==0" >
            <div class="form-group">
                <label>Sezionale</label>
                <select class="form-control form-control-sm p-1" [(ngModel)]="reorderNumber_referenceprefix" >
                    <option *ngFor="let rp of referenceprefix" value="{{rp.code}}">{{rp.code}} ({{rp.label}})</option>
                </select>
            </div>
            <div class="form-group">
                <label>Anno</label>
                <input type="text" class="form-control form-control-sm p-1" [(ngModel)]="reorderNumber_year">
            </div>
        </ng-container>
        <ng-container *ngIf="reorderTab==1">
            <table class="table table-sm" >
                <thead>
                    <th>Num. attuale</th>
                    <th>Nuova num.</th>
                    <th>Data</th>
                    <th>Nominativo</th>
                    <th>SDI</th>
                </thead>
                <tbody>
                    <tr *ngFor="let r of reorderList" [class.bg-warning]="r.toupdate">
                        <td><span class="form-control form-control-sm bg-light">{{r.reference}} {{r.referenceprefix}}</span></td>
                        <td [class.font-weight-bold]="r.toupdate">
                            <span *ngIf="!r['showInputReference']" style="cursor: pointer;" data-toggle="tooltip" title="Click per modificare" (click)="r['showInputReference']=true" class="form-control form-control-sm bg-light text-primary">{{r.new_reference}} {{r.referenceprefix}}</span>
                            <div *ngIf="r['showInputReference']" class="input-group input-group-sm">
                                <input  type="text" class="form-control form-control-sm  py-1" name="new_reference_{{r.id}}" [(ngModel)]="r['new_reference']" (change)="verifyChangeReorder(r)">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-success" (click)="r['showInputReference']=false"><i class="fa fa-check"></i></button>
                                </div>
                            </div>
                        </td>
                        <td><small>{{r.date | date:"dd/MM/yyyy"}}</small>
                        <td><small >{{r.name}}</small>
                        </td>
                        <td>
                            <i data-toggle="tooltip" title="Inviata ed in attesa di risposta" *ngIf="r.sdi_status==1" class="fa fa-clock"></i>
                            <i data-toggle="tooltip" title="Consegnata" *ngIf="r.sdi_status==2" class="fa fa-check"></i>
                            <i data-toggle="tooltip" title="In errore" *ngIf="r.sdi_status==3" class="fa fa-exclamation"></i>
                            <i data-toggle="tooltip" title="Confermata ma con mancata consegna" *ngIf="r.sdi_status==4" class="fa fa-check"></i>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
    <div class="modal-body">
        <button *ngIf="reorderTab==0" class="btn btn-sm btn-secondary m-1 float-left" (click)="btn_prev_reorder();">Annulla</button>
        <button *ngIf="reorderTab==0" class="btn btn-sm btn-secondary m-1 float-right" (click)="btn_next_reorder();">Avanti<i class="fas fa-chevron-right ml-1"></i></button>
        <button *ngIf="reorderTab==1" class="btn btn-sm btn-secondary m-1 float-left" (click)="btn_prev_reorder();"><i class="fas fa-chevron-left mr-1"></i>Indietro</button>
        <button *ngIf="reorderTab==1" class="btn btn-sm btn-success m-1 float-right" (click)="btn_next_reorder();">Rinumera</button>
    </div>
</ng-template>

<ng-template #uploadXMLModal let-modal>
    <div class="modal-body">
        <div class="form-group text-center">
            <label>Selezionare il file XML da caricare <small>(formato Fattura Elettronica)</small></label>
            <div class="m-auto text-center p-2" style="width:90%;height:200px;color:#ccc;border: 1px dashed #ccc;border-radius:10px;cursor:pointer;">
                <i class="fas fa-2x fa-bullseye mt-5"></i><br>
                <i>Trascina e rilascia <b>qui</b>.</i><br>
                <a class="btn btn-primary btn-sm mt-4" (click)="fileToUpload.click()"><i class="fas fa-upload mr-2"></i>Carica file</a>
            </div>
            <input style="display:block;opacity:0;width:90%;height:200px;margin:-200px auto 0;" type="file" #fileToUpload name="fileXMLToUpload" (change)="handleFileXMLInput($event.target.files);confirmUploadXMLModal();">
        </div>
        <button class="btn btn-sm btn-secondary float-right" (click)="closeUploadXMLModal();">Annulla</button>
    </div>
</ng-template>


<ng-template #listFileModal let-modal>
    <div class="modal-header">
        <h4>Seleziona il file da visionare</h4>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closelistFileModal()" >
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
            <button type="button" class="btn btn-default btn-block btn-light text-left" *ngFor="let f of documentSelected.files" (click)="downloadFile(f)">
                <i data-toggle="tooltip" title="{{getExtension(f) | uppercase }}" class="mr-2 fa-2x fas fa-file fa-file-{{getExtension(f)}}"
                [class.fa-file-zipper]="getExtension(f)=='zip' || getExtension(f)=='rar'"
                [class.fa-file-image]="getExtension(f)=='bmp' || getExtension(f)=='jpg' || getExtension(f)=='png'"
                [class.fa-file-video]="getExtension(f)=='mov' ||getExtension(f)=='avi' || getExtension(f)=='mp4'"
                [class.fa-file-audio]="getExtension(f)=='wav' ||getExtension(f)=='mp3' || getExtension(f)=='wma'"
                [class.fa-file-word]="getExtension(f)=='doc' || getExtension(f)=='docx'"
                [class.fa-file-excel]="getExtension(f)=='xls' || getExtension(f)=='xlsx'"
                [class.fa-file-powerpoint]="getExtension(f)=='ppt' || getExtension(f)=='pptx'"
                [class.fa-file-code]="getExtension(f)=='xml'"
                [class.fa-file-signature]="getExtension(f)=='p7m'">
                </i>&nbsp;
                <span class="text-truncate d-inline-block" style="width:90%">{{f.name}}</span>
            </button>
    </div>
    <!-- <div class="modal-footer">
        <button class="btn btn-sm btn-secondary" (click)="closelistFileModal();">Chiudi</button>
    </div> -->
</ng-template>