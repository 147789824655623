<div class="row my-1">

    <div *ngIf="id_product==0" class="col-12 col-lg-3 pr-lg-0">        
    
        <div *ngIf="!user.isBackendUser()" class="m-3">
            <i class="fa-2x fas fa-info text-info mr-3"></i>
            <b>ATTENZIONE</b><br>
            <small>
                <i>Scegli il <b>Magazzino di riferimento</b> e il tipo di <b>giacenza</b>, per avere la lista dei prodotti presenti.</i>
            </small>
        </div>
    
        <small>
            <app-filterdate *ngIf="user.isBackendUser()" [date_type]="100" [class]="'card treeviewlist p-1'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>
            <ul class="card small-box list-group treeviewlist p-1 mt-2">
                <li class="list-group-item" [class.active]="filter_id_inventory.value==''" (click)="filter_id_inventory.value='';getItems()">Tutti i magazzini</li>
                
                <li class="list-group-item" [class.active]="filter_id_inventory.value && filter_id_inventory.value.indexOf(i.id)>-1" *ngFor="let i of inventories"  (click)="setFilterTypeValue('filter_id_inventory',i.id);" style="cursor:pointer;line-height: 1;">{{i.name}}</li>
                 
                <!--
                <li class="list-group-item" [class.active]="filter_id_inventory.value==i.id" *ngFor="let i of inventories" (click)="filter_id_inventory.value=i.id;getItems()">{{i.name}}</li>
                -->
                <div *ngIf="shops && shops.length>2" class="icon"><i class="fas fa-boxes"></i></div>
            </ul>
            <ul class="card small-box list-group p-1 mt-2">
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==0" (click)="filter_stock.mode=0;filter_stock.value='0';filter_stock.valueIsField='false';getItems();" style="cursor:pointer;">Tutti</li>
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==6" (click)="filter_stock.mode=6;filter_stock.value='0';filter_stock.valueIsField='false';getItems();" style="cursor:pointer;">Giacenza positiva</li>
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==7 && filter_stock.value=='0'" (click)="filter_stock.mode=7;filter_stock.value='0';filter_stock.valueIsField='false';getItems();" style="cursor:pointer;">Giacenza negativa</li>
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==1" (click)="filter_stock.mode=1;filter_stock.value='0';filter_stock.valueIsField='false';getItems();" style="cursor:pointer;">Giacenza nulla</li>
                <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_stock.mode==7 && filter_stock.value=='pm.minstock'" (click)="filter_stock.mode=7;filter_stock.value='pm.minstock';filter_stock.valueIsField='true';getItems();" style="cursor:pointer;">Giacenza da riordinare</li>
                
                <div class="icon"><i class="fas fa-cubes"></i></div> 
            </ul>
            <div class="card p-1 mt-2" *ngIf="user.isBackendUser()">
                <input type="date" class="form-control form-control-sm" name="filter_date_stock" [(ngModel)]="filter_date_stock.value">
                <ul class="small-box list-group p-1">
                    
                    <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_date_stock.mode==0" (click)="filter_date_stock.mode=0;getItems();" style="cursor:pointer;">Tutti</li>
                    <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_date_stock.mode==7" (click)="filter_date_stock.mode=7;getItems();" style="cursor:pointer;">Movimentati prima</li>
                    <li class="list-group-item d-flex justify-content-between align-items-center" [class.active]="filter_date_stock.mode==6" (click)="filter_date_stock.mode=6;getItems();" style="cursor:pointer;">Movimentati dopo</li>
                    <div class="icon"><i class="fas fa-truck-loading"></i></div> 
                </ul>
            </div>
        </small>
    </div>
    <div [class.col-lg-9]="id_product==0" [class.col-12]="id_product>0">
        <div class="card card-outline">
            <div class="p-0">
                <div class="row" *ngIf="id_product==0">
                    <div class="col-4">
                        <div class="input-group input-group-info input-group-sm p-1">
                            <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per marca, modello  prodotto " [(ngModel)]="filter_search.value" (keyup.enter)="updateList()">
                            <div class="input-group-append ">
                                <button type="submit" class="btn btn-default" data-toggle="tooltip" title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''" data-toggle="tooltip" title="annulla ricerca" (click)="filter_search.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-2 pl-0" *ngIf="user.isBackendUser()">
                        <div class="input-group input-group-sm m-1" >
                            
                            <select class="form-control form-control-sm" name="filter_product_id_type" [(ngModel)]="filter_product_id_type.value" (ngModelChange)="updateList()">
                                <option value="">Tutte le tipologie</option>
                                <option *ngFor=" let r of type_products" [ngValue]="r.id">{{r.name}}</option>
                            </select>
                            
                        </div>
                    </div>
                    <div class="col-2  pr-0">
                        <div class="input-group input-group-sm m-1" >
                            <select class="custom-select" name="filter_id_category" [(ngModel)]="filter_id_category.value" (ngModelChange)="updateList()">
                                <option value="">Tutte le categorie</option>
                                <option *ngFor="let t of categories" [ngValue]="t.id">{{t.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-4" *ngIf="user.isBackendUser()">
                        <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="far fa-trash-alt"></i><span class="d-none d-sm-inline-block"></span></button>
                        <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right m-1 "><i class="fas fa-trash"></i><span class="d-none d-sm-inline-block"></span></button>
                        <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-undo"></i><span class="d-none d-sm-inline-block"></span></button>
                        <button  (click)="export()" class="btn btn-secondary btn-xs float-right m-1 "><i class="fas fa-file-export"></i><span class="d-none d-sm-inline-block"></span></button>
                        
                        
                        <!--<button class="btn btn-success btn-xs float-right m-1" (click)="fastout()"><i class="fas fa-plus"></i><span class="d-none d-sm-inline-block"></span></button>-->

                    </div>
                </div>
                <div *ngIf="list==null" class="m-4">
                    <i class="fa-2x fas fa-info text-info mr-3"></i>
                    <i>Nessun elemento visualizzato in base ai filtri di ricerca impostati</i>
                </div>
                <form [formGroup]="form" *ngIf="list!=null">
                    <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                        <thead class="bg-light">
                            <th>
                                Codice
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('ps.stock')">
                                    <i class="fas fa-sort {{classOrdering('pm.codeinternal')}}"  ></i>
                                </button>
                            </th>
                            <th >
                                Prodotto
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('pm.name')">
                                    <i class="fas fa-sort {{classOrdering('pm.name')}}"  ></i>
                                </button>
                            </th>
                            <th *ngIf="user.isBackendUser()">Tipologia</th>
                            <th>Categoria
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('pc.name')">
                                    <i class="fas fa-sort {{classOrdering('pc.name')}}"  ></i>
                                </button>
                            </th>
                            <th class="text-center">Giacenza</th>
                            <th class="text-right" *ngIf="user.isBackendUser()">Valore</th>
                            <!-- <th></th> -->
                        </thead>
                        <tbody>
                        
                            <!-- elemento ripetuto -->
                                <tr  *ngFor="let r of list">
                                    <td >
                                        <ng-container *ngIf="r.codeinternal"><span class="badge badge-info">{{r.codeinternal}}</span><br/></ng-container>
                                        <span class="badge badge-warning">{{r.codeexternal}}</span>
                                    </td>
                                    <td >
                                        {{r.brand}} {{r.name}} 
                                        <ng-container *ngIf="r.serials">
                                            <span *ngFor="let s of r.serials">
                                                <ng-container *ngIf="s.avaible>0">
                                                    <span class="badge badge-info mr-2">{{s.sn}}</span>
                                                </ng-container>
                                            </span>
                                        </ng-container>
                                        <i class="fa fa-pencil-alt" *ngIf="user.isBackendUser()" (click)="openProduct(r)"></i>
                                    </td>
                                    <td *ngIf="user.isBackendUser()">{{r.type}}</td>
                                    <td><small>{{r.category}}</small></td>
                                    <td class="text-center" [class.text-success]="r.stock>0" [class.text-danger]="r.stock < 1">
                                        <b>{{r.stock}} </b><small>{{r.unit}}</small>
                                        <i *ngIf="r.stock<r.minstock" data-toggle="tooltip" title="Quantità inferiore allo stock minimo ({{r.minstock}} {{r.unit}})" class="text-warning fas fa-exclamation-triangle"></i>
                                          
                                    </td>
                                    
                                    <td class="text-right" *ngIf="user.isBackendUser()">
                                        {{r.cost | currency:"&euro;"}}
                                    </td>
                                     <td>
                                        <button type="button" class="btn btn-secondary btn-xs float-right m-1" data-toggle="tooltip" title="Sposta gli elementi selezionati in un altro magazzino"
                                         (click)="move(r)"><i class="fas fa-arrows-alt-h"></i></button>
                                    </td> 
                                </tr>
                        </tbody>
                        <tfoot class="bg-white" *ngIf="user.isBackendUser()">
                            <td colspan="4">TOTALE</td>
                            <td class="text-right">{{total_cost | currency:"&euro;"}}</td>
                        </tfoot>
                    </table>
                </form>

                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</div>