import { Component, OnInit, Input,Output, EventEmitter,AfterViewInit } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { Globals } from 'projects/core/src/globals';

import { ShopsService } from '../../services/shops.service';
import { Shops } from '../../db/shops';
import * as L from 'leaflet';
//import * as ExtraMarkers from 'leaflet-extra-markers'

import "leaflet-extra-markers/dist/js/leaflet.extra-markers.js";
import { HttpClient } from '@angular/common/http';
import { Filter, FilterMode } from 'projects/core/src/include/structures';
import { ShopsDetailsComponent } from './shops-details/shops-details.component';
import { TypesService } from '../../services/types.service';
import { Type } from '../../db/type';


@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.sass']
})

export class ShopsComponent extends ModelList<Shops> implements OnInit,AfterViewInit {
    
  

  @Input()
  id_address:number=0;

  @Output()
  firstIdShop:EventEmitter<number>=new EventEmitter();

  

  openSearchBar:boolean=false;

  
  search:string;
  filter_search:Filter=new Filter();
  filter_id_address:Filter=new Filter();
  filter_id_type:Filter<number>=new Filter<number>();
  filter_gps:Filter=new Filter();

  filter_latitude:Filter=new Filter();
  filter_longitude:Filter=new Filter();
  filter_exclude_ids_map:Filter=new Filter();

  list_map:Shops[]=[];
  type_shops:Type[];
  private map:L.Map;
  markerLayer:L.LayerGroup;
   
  isFilterSet=false;
  exclude_ids_map=[];
  constructor(
    private fb1: FormBuilder,
    private shopsService: ShopsService,
    private typesService: TypesService,
    private http:HttpClient
    
  ) {
    super(shopsService,fb1);
    this.detailViewModal=ShopsDetailsComponent;
   }



  ngOnInit(): void {
    this.title = 'Esercizi';

    super.ngOnInit.apply(this, arguments);

  
     

    
    
      this.afterGetItems=(()=>{
        //centra la mappa in corrispondenza dei limiti ricercati
        if(this.id_address==0){
          //cerca le coordinate minori
          let min_lat=99999999999;
          let max_lat=0;
          let min_lon=999999999999;
          let max_lon=0;
          this.exclude_ids_map=[];
          for(let r of this.list){
            this.exclude_ids_map.push(r.id);
            if(r.latitude>0 && r.latitude<min_lat) min_lat=r.latitude;
            if(r.longitude>0 && r.longitude<min_lat) min_lon=r.longitude;
            if(r.latitude>0 && r.latitude>max_lat) max_lat=r.latitude;
            if(r.longitude>0 && r.longitude>max_lon) max_lon=r.longitude;
          }

          
          if(min_lat==max_lat){
            this.map.setView(L.latLng(min_lat,min_lon),17);
          }else{
            this.map.flyToBounds(L.latLngBounds([min_lat,min_lon],[max_lat,max_lon]));
          }

          this.onMapMove();
        }
        //se c'è un elenco di esercizio, scaturisci l'envento firstIdShop
        if(this.list.length>0)
          this.firstIdShop.emit(this.list[0].id);
      });
   

   

    

    

  }

  setFilters():void{
    
    if(this.id_address>0){
      this.filter_id_address.mode=FilterMode.normal;
      this.filter_id_address.fields=[];
      this.filter_id_address.fields.push("s.id_address");
      this.filter_id_address.value=this.id_address.toString();
      this.filter.push(this.filter_id_address);

    }

    this.filter_id_type.mode=FilterMode.normal;
    this.filter_id_type.fields=[];
    this.filter_id_type.nullvalue=0;
    this.filter_id_type.value=(this.type_shops && this.type_shops.length)>0?this.type_shops[0].id:0;
    this.filter_id_type.fields.push("s.id_type");
    this.filter.push(this.filter_id_type);

    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.value="1";
    this.filter_status.fields.push("s.status");
    this.filter.push(this.filter_status);

    this.filter_search.mode=FilterMode.like;
    this.filter_search.fields=[];
    this.filter_search.fields.push("s.name");
    this.filter_search.fields.push("s.address");
    this.filter_search.fields.push("s.city");
    this.filter_search.value="";
    this.filter.push(this.filter_search);


    this.filter_gps.mode=FilterMode.custom;
    this.filter_gps.fields=[];
    this.filter_gps.enabled=false;
    this.filter_gps.value="s.latitude=0 AND s.longitude=0";
    this.filter.push(this.filter_gps);

    this.isFilterSet=true;
  }

  ngAfterViewInit(){
    this.initMap();
    this.typesService.getTypes("shops").subscribe((items)=>{
      this.type_shops=items;
      if(this.mode!="embedded") //se incoroporato in una finestra non avviare subito la ricerca, ma aspetta che il filtro sia attivo
        this.updateList(this.id_address);
    });
    
  }

  ngOnChanges():void{
    if(this.id_address>0) //avvia la ricerca quando il filtro è attivo
      this.updateList(this.id_address);
  }

  
  updateList(id_address:number):void{
    if(!this.isFilterSet)
      this.setFilters();

  
    
    this.getItems();
  }

  getItemsMap(){

    let filter=[];
    
    this.filter_status.mode=FilterMode.normal;
    this.filter_status.fields=[];
    this.filter_status.fields.push("s.status");
    
    filter.push(this.filter_status);

    this.filter_latitude.mode=FilterMode.minmax;
    this.filter_latitude.fields=[];
    this.filter_latitude.fields.push("s.latitude");
    filter.push(this.filter_latitude);

    this.filter_exclude_ids_map.mode=FilterMode.not_contain;
    this.filter_exclude_ids_map.fields=[];
    this.filter_exclude_ids_map.fields.push("s.id");

    filter.push(this.filter_exclude_ids_map);


    

    this.shopsService.getItems(filter,[],0,20,"",[]).subscribe((items)=>{
      this.list_map=items.value;
      this.updateMap();
    })

  }



  private initMap():void{

    if(this.mode=="embedded")
      return;
   
    this.map = L.map('map_shops', {
      center: [ 38.1037, 15.6369 ],
      zoom: 12,

    });

    this.map.on("zoomend",this.onMapMove.bind(this));
    this.map.on("dragend",this.onMapMove.bind(this));
   
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19
    });

    
    tiles.addTo(this.map);
  }

  private resetMap():void{
    this.map.invalidateSize();
  }

  private updateMap(): void {

    if(this.map==undefined){
      this.initMap();
    }else{
      if(this.markerLayer!=undefined)
        this.map.removeLayer(this.markerLayer);
    }

    this.markerLayer= L.layerGroup();

    let arrayOfLatLngs=[];
    for(let r of this.list_map){
      if(r.latitude>0 && r.longitude>0){
      
        let c=L.latLng(r.latitude,r.longitude);
        let icon=L.ExtraMarkers.icon({
          icon: 'fa-user fa-number initials',
          markerColor: 'orange',
          shape: 'circle',
          prefix: 'text-sm',
          number:r.name.substr(0,3).toUpperCase(),
          
        });

        let m=L.marker(c,{icon:icon });
       

        
        m.bindPopup('<strong>'+r.name+'</strong><br>'+r.activity);
        m.on("click",function(event){
          m.openPopup();
        });

        arrayOfLatLngs.push(c)
        this.markerLayer.addLayer(m);
      }
    }

    for(let r of this.list){
      if(r.latitude>0 && r.longitude>0){
      
        let c=L.latLng(r.latitude,r.longitude);
        let icon=L.ExtraMarkers.icon({
          icon: 'fa-user fa-number initials',
          markerColor: 'blue-dark',
          shape: 'circle',
          prefix: '',
          number:r.name.substr(0,3),
          
        });

        let m=L.marker(c,{icon:icon });
       

        
        m.bindPopup('<h6 class="mb-0">'+r.name+'</h6><span>'+r.activity+'</span><br><br><span>'+r.address+'</span><br><b>'+r.city+'</b>');
        m.on("click",function(event){
          m.openPopup();
        });

        arrayOfLatLngs.push(c)
        this.markerLayer.addLayer(m);
      }
    }



    this.map.addLayer(this.markerLayer);
    
    
  }

  onMapMove(event=null):void{

    
    this.filter_latitude.value2=this.map.getBounds().getNorthWest().lat.toString();
    this.filter_latitude.value=this.map.getBounds().getSouthEast().lat.toString();
    this.filter_longitude.value2=this.map.getBounds().getSouthEast().lng.toString();
    this.filter_longitude.value=this.map.getBounds().getNorthWest().lng.toString();
    this.filter_exclude_ids_map.value=this.exclude_ids_map.join(",");
    this.getItemsMap();

    const coordinate=this.map.getCenter();

     //ricerca l'indirizzo
    let url="http://dev.virtualearth.net/REST/v1/Locations/"+coordinate.lat+","+coordinate.lng+"?key="+Globals.config.config['bingKey'];
    this.http.get<string>(url).toPromise<string>().then((value)=>{
      const r=value['resourceSets'][0]['resources'][0];
      this.search=r['name'];
      
    });
  }

  setCenterMap(shop:Shops):void{
    if(shop.latitude>0 && shop.longitude>0){
      this.map.setView(L.latLng(shop.latitude,shop.longitude),18);
    }
  }

  geocoding():void{
    Globals.setLoading(true);
    let url="http://dev.virtualearth.net/REST/v1/Locations?query="+this.search+"&key="+Globals.config.config['bingKey'];
    this.http.get<string>(url).toPromise<string>().then((value)=>{
      Globals.setLoading(false);
      const r=value['resourceSets'][0]['resources'][0];
      const c=r['point']['coordinates'];
      this.map.setView(L.latLng(c[0],c[1]),18);
      this.onMapMove();
        
    });
    

  }


 

}
