<app-window [title]="title">
    <form role="form" id="detailForm">
        <div class="row">

            <div class="col-12">
                <div class="row">
                    <div class="col-lg-2">
                        <div class="form-group">
                            <label>Data</label>
                            <div class="input-group" >
                                <input class="form-control form-control-sm" type="date"  name="date"  [(ngModel)]="record.date"  />
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-group">
                            <label>Tipologia</label>
                            <select class="form-control form-control-sm" [(ngModel)]="record.id_type" name="id_type" #id_type="ngModel" required>
                                <option value="1">Ingresso</option>
                                <option value="2">Uscita</option>
                                
                            </select>
                            <div *ngIf="id_type.invalid && (id_type.dirty || id_type.touched)" class="alert alert-danger">
                                <div *ngIf="id_type.errors.required">Campo obbligatorio.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="form-group">
                            <label>Causale</label>
                            <input type="text" class="form-control form-control-sm" placeholder="..." name="description" [(ngModel)]="record.description">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-9">
                        <div class="form-group">
                            <label>Anagrafica</label>

                            <div class="alert alert-info p-1">
                                <app-selectfieldcustom [showViewButton]="true" [showSearchButton]="true"  (OnExplore)="selectAddress()" [recordSelected]="record.addressItem" [service]="addressesService"  [fieldRender]="'name'" [class]="'form-control form-control-sm'" (OnRecordSelected)="OnSelectAddress($event)" (OnRecordView)="OnViewAddress($event)"></app-selectfieldcustom>
                                <div class="mt-w" *ngIf="record.addressItem && record.addressItem.id>0">
                                    <small>
                                        <div class="row">
                                            <div class="col-6 mt-2">
                                                <label>Dati generali</label><br/>
                                                <i class="fa fa-user mr-2" *ngIf="record.addressItem.city"></i>{{record.addressItem.address}}<span *ngIf="record.addressItem.city">,</span> {{record.addressItem.zip}}  <b>{{record.addressItem.city}}</b> {{record.addressItem.country}} 
                                                <span *ngIf="record.addressItem.vat_number"> - P.IVA {{record.addressItem.vat_number}}</span>
                                        
                                            </div>
                                            <div class="col-6 mt-2">
                                                <label>Contatti</label>
                                                <ul class="list-group">
                                                    <li  *ngFor="let c of record.addressItem.contacts">{{c.value}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                    </small>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div class="form-group mb-2">
                            <label>Importo</label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                                <input type="number" class="form-control form-control-sm"  min="0" step="0.001" max="9999999999" name="amount" placeholder="..." 
                                oninput="validity.valid||(value='');"  [(ngModel)]="record.amount" (change)="calculateGross()">
                            </div>
                        </div>
                        <div class="form-group  mb-2">
                            <label>IVA</label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">%</span>
                                </div>
                                <input type="number" class="form-control form-control-sm"  min="0" max="100" name="tax" 
                                oninput="validity.valid||(value='');"  [(ngModel)]="record.tax"  (change)="calculateGross()">
                            </div>
                        </div>
                        <div class="form-group mb-2">
                            <label>Totale <small>(Importo + IVA)</small></label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                                <input type="number" class="form-control form-control-sm"  min="0" step="0.01" name="gross" placeholder="..." 
                                oninput="validity.valid||(value='');"  [(ngModel)]="record.gross" (change)="calculateNet()">
                            </div>
                        </div>
                        <div class="form-group  mb-2" *ngIf="show_paid">
                            <label>Versato</label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                                <input type="number" class="form-control form-control-sm" min="0" step="0.01" name="paid" placeholder="..."
                                oninput="validity.valid||(value='');" [(ngModel)]="record.paid " >
                                <div class="input-group-append">
                                    <a type="button" class="btn btn-sm btn-default" data-toggle="tooltip" title="Versato tutto" (click)="record.paid=record.gross"><i class="fas fa-equals"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="form-group input-group-sm" *ngIf="show_paid">
                            <label>Differenza <small>(Totale - Versato)</small></label>
                            <div class="input-group input-group-sm">
                                <div class="input-group-prepend ">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                                <p class="form-control form-control-sm bg-light" [class.text-success]="record.paid - record.gross > 0" [class.text-danger]="record.paid - record.gross < 0">{{ record.paid - record.gross | currency:' ' }}</p> 
                            </div>
                        </div>
                    </div>


                    <div class="col-sm-7">
                        <div class="form-group">
                            <label>Metodo di pagamento</label>
                            <select class="form-control form-control-sm" name="method_payment" placeholder="..." #methodpayment="ngModel" [(ngModel)]="record.method_payment">
                                <option *ngFor="let m of methodpayments" (value)="m.name">{{m.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="form-group ">
                            <label>Operatore</label>
                            <div class="input-group input-group-sm">
                                <select class="form-control form-control-sm" [(ngModel)]="record.id_user" name="id_user" #id_user="ngModel" required>
                                    <option *ngFor="let t of users" [ngValue]="t.id">{{t.username}}</option>
                                </select>
                                <div *ngIf="id_user.invalid && (id_user.dirty || id_user.touched)" class="alert alert-danger">
                                    <div *ngIf="id_user.errors.required">Campo obbligatorio.</div>
                                </div>
                                <div class="input-group-append">
                                    <select class="form-control form-control-sm" name="created_from" #created_from="ngModel" [(ngModel)]="record.created_from">
                                        <option *ngFor="let s of shops" [ngValue]="s.id">{{s.name}}</option>
                                    </select>
                                </div>
                            </div>     
                        </div>
                    </div>

                    <div class="col-sm-6">
                        <div class="form-group ">
                            <label>Cassa / Banca</label>
                            <div class="input-group input-group-sm">
                                <select class="form-control form-control-sm" [(ngModel)]="record.source" name="source" #id_user="ngModel">
                                    <option *ngFor="let s of sources" [ngValue]="s.description">{{s.description}}</option>
                                </select>
                               
                            </div>     
                        </div>
                    </div>

                    <div class="col-sm-6" *ngIf="isModuleEnabled('financialaccounts')">
                        <div class="form-group ">
                            <label>Conti contabili</label>
                            <div class="input-group input-group-sm">
                                <app-financialaccount [id_financialaccount]="record.id_financialaccount" (selected)="record.id_financialaccount=$event['id'];"></app-financialaccount>
                              
                            </div>     
                        </div>
                    </div>


                </div>
                
                <!-- Extra fields -->
                <section *ngIf="record.customfields">
                    <app-customfields  [list]="record.customfields" [table]="'cashflow'" [id_table]="record.id" [condition_id]="'1'" [condition_field]="record.method_payment"></app-customfields>
                </section>
                
                <div class="row">
                    <div class="col-sm-7">
                        <div class="form-group">
                            <label>Note</label>
                            <div class="input-group">
                                <textarea  class="form-control" rows="4" name="note" [(ngModel)]="record.note" placeholder="..."></textarea>
                            </div> 
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="form-group">
                            <label>Elemento collegato</label>
                            <div class="input-group bg-light border">
                                <p class="p-2 m-0">
                                    <app-reference #referenceRecord [showConnectBtn]="true" [showDisconnect]="true" (recordConnected)="connectActivity($event)" [reference]="record.reference" [table]="record.table" [mode]="'detail'"></app-reference>
                                </p>
                                
                            </div> 
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </form> 

    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>

