<app-window [title]="title">
    <form role="form" id="detailForm">

        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#tab1" role="tab" aria-controls="home" aria-selected="true">
                    <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Generale</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fab fa-diaspora"></i><span class="d-none d-sm-inline-block ml-2">Caratteristiche</span></a>
            </li>
            <li class="nav-item" *ngIf="record.id>0 && isModuleEnabled('inventories')">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fa-boxes fas"></i><span class="d-none d-sm-inline-block ml-2">Magazzino</span></a>
            </li>
            <li class="nav-item" *ngIf="record.id>0 && isModuleEnabled('documents')">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab6" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-file-alt"></i><span class="d-none d-sm-inline-block ml-2">Documenti</span></a>
            </li>
            <li class="nav-item" *ngIf="record.id>0 && record.has_serials && isModuleEnabled('installations')">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab4" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-barcode"></i><span class="d-none d-sm-inline-block ml-2">Seriali</span></a>
            </li>
            
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab5" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-desktop"></i><span class="d-none d-sm-inline-block ml-2">Scheda web</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab7" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-sitemap"></i><span class="d-none d-sm-inline-block ml-2">Correlati</span></a>
            </li>
        </ul>
        

        <!--
        <div class="card-body float-right" *ngIf="parent.isEditing">
            <button class="btn btn-sm btn-success float-right m-1" [disabled]="disable()" (click)="save();" ><i class="fas fa-save"></i><span class="d-none d-sm-inline-block">&ensp;Salva</span></button>
            <button class="btn btn-sm btn-secondary float-right m-1" (click)="onBack()" ><i class="fas fa-times"></i><span class="d-none d-sm-inline-block">&ensp;Annulla</span></button>
        </div>-->


        <div class="p-2"> 
            <div class="tab-content" id="myTabContent" >
                <!-- GENERALE-->
                <div class="tab-pane fade show active card-body" id="tab1" role="tabpanel" aria-labelledby="tab1-tab">
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>ID</label>
                        </div>
                        <div class="col-lg-2">
                            <input readonly type="text" class="form-control form-control-sm alert-info" name="id" placeholder="..." #id="ngModel" [(ngModel)]="record.id">
                        </div>
                        <div class="col-lg-4 text-right">
                            <label>Tipologia</label>
                        </div>
                        <div class="col-lg-4">

                            <app-type [mode]="2" [id_type]="record.id_type" [table]="'products'" [record]="record" ></app-type>
                            <!--
                            <select class="form-control form-control-sm" [(ngModel)]="record.id_type" name="id_type" #id_type="ngModel" required>
                                <option *ngFor="let t of type_products" [ngValue]="t.id">{{t.name}}</option>
                            </select>
                            <div *ngIf="id_type.invalid && (id_type.dirty || id_type.touched)" class="alert alert-danger">
                                <div *ngIf="id_type.errors.required">Campo obbligatorio.</div>
                            </div>
                        -->
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Descrizione</label>
                        </div>
                        <div class="col-lg-10">
                            <input autofocus required type="text" class="form-control form-control-sm" name="name" placeholder="..." #name="ngModel" [(ngModel)]="record.name">
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Cod. Art.</label>
                        </div>
                        <div class="col-lg-2 pr-lg-right">
                            <input autofocus required type="text" class="form-control form-control-sm" name="codeinternal" placeholder="..." #codeinternal="ngModel" [(ngModel)]="record.codeinternal">
                        </div>
                        <div class="col-lg-2 text-right">
                            <label>Cod. For.</label>
                        </div>
                        <div class="col-lg-2 pr-lg-right text-right">
                            <input  type="text" class="form-control form-control-sm" name="codeexternal" placeholder="..." #codeexternal="ngModel" [(ngModel)]="record.codeexternal">
                        </div>
                        
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>EAN (singolo)</label>
                        </div>
                        <div class="col-lg-2">
                            <input  type="text" class="form-control form-control-sm" name="barcode" placeholder="..." #barcode="ngModel" [(ngModel)]="record.barcode">
                        </div>

                        <div class="col-lg-2">
                            <label>EAN (pacco)</label>
                        </div>
                        <div class="col-lg-2">
                            <input  type="text" class="form-control form-control-sm" name="barcode_pack" placeholder="..." #barcode_pack="ngModel" [(ngModel)]="record.barcode_pack">
                        </div>

                        <div class="col-lg-2">
                            <label>Quantita EAN (pacco)</label>
                        </div>
                        <div class="col-lg-2">
                            <input  type="text" class="form-control form-control-sm" name="barcode_quantity" placeholder="..." #barcode_quantity="ngModel" [(ngModel)]="record.barcode_quantity">
                        </div>
                    </div>
                    <div class="form-group row mb-1" *ngIf="isModuleEnabled('installations')">
                        <div class="col-lg-2 pr-0">
                            <label>Prodotto con seriali</label>
                        </div>
                        <div class="col-md-1">
                            <input type="checkbox" name="has_serials" placeholder="..." #has_serials="ngModel" [(ngModel)]="record.has_serials" >
                        </div>
                        <div class="col-lg-3 text-right" *ngIf="record.has_serials">
                            <label>Pattern per il SN <small>(autogenerato)</small></label>
                        </div>
                        <div class="col-lg-6" *ngIf="record.has_serials">
                            <input  type="text" class="form-control form-control-sm" placeholder="ex. AA{sn}" name="snpattern" #snpattern="ngModel" [(ngModel)]="record.snpattern">
                        </div>
                    </div>
                    <div class="form-group row mb-1" *ngIf="isModuleEnabled('inventories')">
                        <div class="col-lg-2 pr-0">
                            <label>Prodotto a magazzino</label>
                        </div>
                        <div class="col-lg-1 pr-0">
                            <input type="checkbox" name="stockable" placeholder="..." #stockable="ngModel" [(ngModel)]="record.stockable" >
                        </div>
                        <div class="col-lg-5 pl-0">
                            <small *ngIf="record.stockable"><i>(l'articolo può essere movimentato tra i magazzini)</i></small>
                            <small *ngIf="!record.stockable"><i>(l'articolo non può essere movimentato tra i magazzini)</i></small>
                        </div>
                        <div class="col-lg-2 text-right">
                            <label>U.M.</label>
                        </div>
                        <div class="col-lg-2">
                            <input  type="text" class="form-control form-control-sm" name="unit" placeholder="..." #unit="ngModel" [(ngModel)]="record.unit">
                        </div>
                    </div>
                    <div class="form-group row mb-1" *ngIf="isModuleEnabled('installations')">
                        <div class="col-lg-2 pr-0">
                            <label>Prodotto noleggiabile</label>
                        </div>
                        <div class="col-md-1">
                            <input type="checkbox" name="rentable" placeholder="..." #rentable="ngModel" [(ngModel)]="record.rentable" >
                        </div>
                    </div>
                    <hr/>
                    <div class="form-group row mb-1" *ngIf="isModuleEnabled('address')">
                        <div class="col-lg-2">
                            <label>Fornitore</label>
                        </div>
                        <div class="col-lg-10">
                            <div class="input-group">
                                <input *ngIf="record.supplier" type="text" class="form-control form-control-sm" value="{{record.supplier.name}}"/>
                                <div class="input-group-append ">
                                    <a class="btn btn-sm btn-default" (click)="selectSupplier()"><span class="d-none d-sm-inline-block mr-2">Seleziona</span><i class="nav-icon fas fa-store-alt"></i></a>
                                    <a class="btn btn-sm btn-default" *ngIf="record.id_supplier>0" (click)="record.id_supplier=0;record.supplier=null;"><i class="fa fa-times"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Marca</label>
                        </div>
                        <div class="col-lg-10">
                            <div class="input-group">
                                <select class="form-control form-control-sm" [(ngModel)]="record.id_brand" name="id_brand" #id_brand="ngModel" required>
                                    <option *ngFor="let t of brands" [ngValue]="t.id">{{t.name}}</option>
                                </select>

                                <div class="input-group-append ">
                                    <a class="btn btn-sm btn-default" (click)="openBrandsManager()"><i class="fa fa-cog"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Categoria</label>
                        </div>
                        <div class="col-lg-10">
                            <select class="form-control form-control-sm" [(ngModel)]="record.id_category" name="id_category" #id_category="ngModel" required>
                                <option *ngFor="let t of categories" [ngValue]="t.id"><span *ngFor="let i of [].constructor(t.level-1)"> - </span>{{t.name}}</option>
                            </select>
                            <div *ngIf="id_category.invalid && (id_category.dirty || id_category.touched)" class="alert alert-danger">
                                <div *ngIf="id_category.errors.required">Campo obbligatorio.</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-0">
                        <div class="col-lg-2">
                            <label>Costo</label>
                        </div>
                        <div class="col-lg-2 pr-lg-0">
                            <div class="input-group input-group-sm">
                                <input type="number" class="form-control form-control-sm"  min="0" step="0.001" 
                                name="netcost" placeholder="..." [(ngModel)]="record.netcost" (keyup)="calculateCost()" (change)="calculateCost()">
                                <div class="input-group-append">
                                    <span class="input-group-text">&euro;</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-1 pr-lg-0 text-lg-right">
                            <label>Sconti <small>%</small></label>
                        </div>
                        <div class="col-lg-1 pr-lg-0">
                            <div class="input-group input-group-sm">
                                <input data-toggle="tooltip" title="% Sconto 1"  class="form-control form-control-sm" 
                                 min="0" step="1" name="discountcost1" placeholder="..." 
                                [(ngModel)]="record.discountcost1" (keyup)="calculateCost()" (change)="calculateCost()">
                            </div>
                        </div>
                        <div class="col-lg-1 px-lg-0">
                            <div class="input-group input-group-sm">
                                <span class="p-2">+</span>
                                <input  data-toggle="tooltip" title="% Sconto 2" class="form-control form-control-sm" 
                                 min="0" step="1" name="discountcost2" placeholder="..." 
                                [(ngModel)]="record.discountcost2" (keyup)="calculateCost()" (change)="calculateCost()">
                            </div>
                        </div>
                        <div class="col-lg-1 px-lg-0">
                            <div class="input-group input-group-sm">
                                <span class="p-2">+</span>
                                <input data-toggle="tooltip" title="% Sconto 3" class="form-control form-control-sm" 
                                 min="0" step="1" name="discountcost3" placeholder="..."
                                [(ngModel)]="record.discountcost3" (keyup)="calculateCost()" (change)="calculateCost()">
                            </div>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                            <label>Totale <small>IVA esclusa</small></label>
                        </div>
                        <div class="col-lg-2 pl-lg-0">
                            <div class="input-group input-group-sm">
                                <input readonly type="text" class="form-control form-control-sm" value="{{record.netcost*(1-record.discountcost1/100)*(1-record.discountcost2/100)*(1-record.discountcost3/100) | currency:'&euro;'}}"/>
                                <div class="input-group-append"><span class="input-group-text">&euro;</span></div>
                            </div>
                        </div>
                       
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-9 text-lg-right">
                            <label>IVA <small>%</small></label>
                        </div>
                        <div class="col-lg-1 px-lg-1">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control form-control-sm" placeholder="" name="taxcost" placeholder="..." #tax="ngModel" [(ngModel)]="record.taxcost" (keyup)="calculateCost();">
                            </div>
                        </div>
                        <div class="col-lg-2 pl-lg-0">
                            <div class="input-group input-group-sm">
                                <input readonly type="text" class="form-control form-control-sm" value="{{(record.netcost*(1-record.discountcost1/100)*(1-record.discountcost2/100)*(1-record.discountcost3/100))*record.taxcost/100 | currency:'&euro;'}}"/>
                                <div class="input-group-append"><span class="input-group-text">&euro;</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-2">
                        <div class="col-lg-10 text-lg-right">
                            <label>Totale <small>IVA inclusa</small></label>
                        </div>
                        <div class="col-lg-2 pl-lg-0">
                            <div class="input-group input-group-sm">
                                <input type="text" class="form-control form-control-sm" value="{{record.grosscost}}" (keyup)="calculateCostNet($event);"/>
                                <div class="input-group-append"><span class="input-group-text">&euro;</span></div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="form-group row mb-2">
                        <div class="col-lg-2">
                            <label>Scorta minima</label>
                        </div>
                        <div class="col-lg-2">
                            <div class="input-group input-group-sm">
                                <input type="number" class="form-control form-control-sm" min="1" step="1" name="minstock" [(ngModel)]="record.minstock" />
                                <div class="input-group-append">
                                    <span class="input-group-text">{{record.unit}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2 text-lg-right">
                            <label>Sconto massimo</label>
                        </div>
                        <div class="col-lg-2">
                            <div class="input-group input-group-sm">
                                <input type="number" class="form-control form-control-sm" min="1" step="1" name="maxdiscount" [(ngModel)]="record.maxdiscount" />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 text-lg-right">
                            <label>IVA <small>(vendita)</small></label>
                        </div>
                        <div class="col-lg-2">
                            <div class="input-group input-group-sm">
                                <input type="number" class="form-control form-control-sm" min="1" step="1" name="tax" [(ngModel)]="record.tax" />
                                <div class="input-group-append">
                                    <span class="input-group-text">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                     <!-- LISTINO PREZZI-->
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Listino Prezzi</label>
                        </div>
                        <div class="col-lg-10">
                            <table class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm" >
                                <thead class="bg-light">
                                    <th>Descrizione</th>
                                    <th>Ricarico</th>
                                    <th>Prezzo <small>(IVA esclusa)</small></th>
                                    <th>Prezzo <small>(IVA inclusa)</small></th>
                                    <th>
                                        <button class="btn btn-xs btn-success float-right" (click)="addPricelist()"><i class="fa fa-plus"></i></button>
                                    </th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let p of record.pricelist;let i=index">
                                        <td>
                                            <input  class="form-control form-control-sm" type="text" size="3"   name="tag[{{p.id}}]" placeholder="..." [(ngModel)]="p.tag" >
                                        </td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input  class="form-control form-control-sm" type="number" size="3"   min="0" step="1" name="markup[{{p.id}}]" placeholder="..." [(ngModel)]="p.markup" (keyup)="calculateMarkup2Price(p)" >
                                                <div class="input-group-append">
                                                    <span class="input-group-text">%</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input class="form-control form-control-sm" type="number" size="10"  min="0" step="0.01" name="price[{{p.id}}]" placeholder="..." [(ngModel)]="p.price" (keyup)="calculatePrice2Markup(p)" >
                                                <div class="input-group-append">
                                                    <span class="input-group-text">&euro;</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="input-group input-group-sm">
                                                <input class="form-control form-control-sm" type="number" size="10"  min="0" step="0.01" name="pricegross[{{p.id}}]" placeholder="..." [(ngModel)]="p.grossprice" (keyup)="calculatePriceGross2Markup(p)" >
                                                <div class="input-group-append">
                                                    <span class="input-group-text">&euro;</span>
                                    
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <button class="btn btn-xs btn-danger float-right" (click)="removePricelist(p)"><i class="fa fa-trash"></i></button>
                                        </td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- CARATTERISTICHE-->
                <div class="tab-pane card-body" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                    <app-customfields  [list]="" [tag]="'features'" [table]="'installations'" [id_table]="record.id" [condition_id]="record.id_category"  [condition_field]="'category'"></app-customfields>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Tags</label>
                        </div>
                        <div class="col-lg-10">
                            <input  type="text" class="form-control form-control-sm" name="tags" placeholder="..." #tags="ngModel" [(ngModel)]="record.tags">
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Dimensioni</label>
                        </div>
                        <div class="col-lg-10">
                            <div class="input-group input-group-sm">
                                <input  type="text" class="form-control form-control-sm" name="height" placeholder="..." #height="ngModel" [(ngModel)]="record.height">
                                <div class="input-group-append">
                                    <span class="input-group-text">cm</span>
                                </div>
                                <input  type="text" class="form-control form-control-sm" name="width" placeholder="..." #width="ngModel" [(ngModel)]="record.width">
                                <div class="input-group-append">
                                    <span class="input-group-text">cm</span>
                                </div>
                                <input  type="text" class="form-control form-control-sm" name="lenght" placeholder="..." #lenght="ngModel" [(ngModel)]="record.lenght">
                                <div class="input-group-append">
                                    <span class="input-group-text">cm</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-lg-2">
                            <label>Peso</label>
                        </div>
                        <div class="col-lg-4">
                            <div class="input-group input-group-sm">
                                <input  type="text" class="form-control form-control-sm" name="weight" placeholder="..." #weight="ngModel" [(ngModel)]="record.weight">
                                <div class="input-group-append">
                                    <span class="input-group-text">Kg</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- MAGAZZINO -->
                <div class="tab-pane" id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                   
                    <app-inventories [mode]="'embedded'" [id_product]="record.id" ></app-inventories>
                </div>
                <!-- DOCUMENTI -->
                <div class="tab-pane" id="tab6" role="tabpanel" aria-labelledby="tab6-tab">
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-sm table-striped">
                                <thead>
                                    <th>Data</th>
                                    <th>Documento</th>
                                    <th>Tipo</th>
                                    <th>Intestazione</th>
                                    <th>Codice</th>
                                    <th>Stato</th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let d of documents" >
                                        <td>
                                            <span *ngIf="d.id_type==2">Ingresso</span>
                                            <span *ngIf="d.id_type==1">Uscita</span>
                                        </td>
                                        <td>
                                            {{d.date | date:"d/M/Y"}}
                                        </td>
                                        <td>
                                            {{getTypeDocument(d.typedocument)}}
                                        </td>
                                        <td>
                                            <span *ngIf="d.id_type==1">Vendita</span>
                                            <span *ngIf="d.id_type==2">Acquisto</span>
                                        </td>
                                        <td>
                                            {{d.name}}
                                        </td>
                                        <td>
                                            <span class="badge badge-warning m-1" *ngIf="d.state==1">da confermare</span>
                                            <span class="badge badge-success m-1" *ngIf="d.state==2">Confermato</span>
                                            <span class="badge badge-warning text-danger m-1" *ngIf="d.id_inventory_detail_unload>0"><i class="fa fa-boxes mr-1"></i>Scaricato</span>
                                            <span class="badge badge-warning text-success m-1" *ngIf="d.id_inventory_detail_load>0"><i class="fa fa-boxes mr-1"></i>Caricato</span>
                                        </td>
                                        <th><button (click)="openDocument(d)" class="btn btn-sm btn-secondary float-right"><i class="fa fa-pencil-alt"></i></button></th>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- SERIALI -->
                <div class="tab-pane" id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                    <app-serials-list [mode]="'embedded'" [id_product]="record.id"></app-serials-list>
                    <!--<div class="row">
                        <div class="col-12">
                            <table class="table table-sm">
                                <tr *ngFor="let s of record.serials">
                                    <td>
                                        <span *ngIf="s.avaible>0"><i class="text-success fa fa-check"></i></span>
                                        <span *ngIf="s.avaible==0"><i class="text-danger fa fa-times"></i></span>
                                        <span *ngIf="s.avaible>1 || s.avaible < 0"><i class="text-warning fas fa-exclamation-triangle"></i></span>
                                        {{s.sn}}
                                        <span *ngIf="s.avaible>1 || s.avaible < 0" class="badge badge-warning badge-pill">{{s.avaible}}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>-->
                </div>
                <!-- SCHEDA WEB -->
                <div class="tab-pane card-body" id="tab5" role="tabpanel" aria-labelledby="tab5-tab">
                    <div class="row">
                        <div class="col-lg-4">
                            <div class="form-group row mb-1">
                                <div class="col-lg-6 text-center mt-3" *ngFor="let i of record.images">
                                
                                    <img src="{{i.imagePath}}" class="img-thumbnail" style="height: 100px;"><br>
                                    <button (click)="removeImage(i)" class="btn btn-sm btn-danger mt-1"><i class="fa fa-trash mr-1"></i> Elimina</button>
                                
                                </div>
                                <div class="col-lg-6 text-center mt-4">
                                    <button class="btn btn_add_photo btn-default" (click)="fileToUpload.click()"><i class="fa fa-3x fa-plus"></i><br/>Aggiungi foto</button>
                                </div>
                                <input type="file" style="display: none;" #fileToUpload name="fileToUpload" (change)="handleFileInput($event.target.files)">
                            </div>
                        </div>
                        <div class="col-lg-8">
                        <div class="form-group mb-1">
                            <div class="col-12"><label>Titolo</label></div>
                            <div class="col-12">
                                <input type="text" name="title" class="form-control form-control-sm" [(ngModel)]="record.title"/>
                            </div>
                        </div>
                        <div class="form-group mb-1">
                            
                            <div class="col-12">
                                <label>Descrizione</label>
                            </div>
                            <div class="col-12">
                                <textarea class="form-control form-control-sm" name="description" [(ngModel)]="record.description" rows="20"></textarea>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                <!-- Correlati -->
                <div class="tab-pane card-body" id="tab7" role="tabpanel" aria-labelledby="tab7-tab">
                    <div class="row">
                        <div class="col-lg-12">
                            <ul class="list-group">
                                <li *ngFor="let p of record.correlations">
                                    <div class="row">
                                        <div class="col-10">
                                            <span class="badge badge-info">{{p.codeinternal}}</span> {{p.name}}
                                        </div>
                                        <div class="col-2">
                                            <button class="btn btn-xs btn-danger" (click)="removeCorrelation(p);"><i class="fa fa-trash"></i></button>
                                            <button class="btn btn-xs btn-info" (click)="openCorrelation(p);"><i class="fa fa-eye"></i></button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <button class="btn btn-success btn-xs" (click)="addCorrelation()">Aggiungi</button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>


    </form>
</app-window>
<app-toolbarmodel [model]="this"></app-toolbarmodel>
