<div class="form-group" *ngIf="mode==1">
    <label>Tipologia</label>
    <div class="input-group input-group-sm">
        <select class="form-control form-control-sm" [(ngModel)]="id_type" (change)="OnTypeChange($event)" >
            <option *ngFor="let t of list" [ngValue]="t.id">{{t.name}}</option>
        </select>
        <div class="input-group-append">
            <button class="btn btn-secondary" (click)="openModalListTypes()"><i class="fas fa-cog" title="Imposta tipologia" data-toggle="tooltip"></i></button>
        </div>
    </div>
</div>

<ng-container *ngIf="mode==2">
    <div class="input-group input-group-sm">
        <select class="form-control form-control-sm" [(ngModel)]="id_type" (change)="OnTypeChange($event)" >
            <option *ngFor="let t of list" [ngValue]="t.id">{{t.name}}</option>
        </select>
        <div class="input-group-append">
            <button class="btn btn-secondary" (click)="openModalListTypes()"><i class="fas fa-cog" title="Imposta tipologia" data-toggle="tooltip"></i></button>
        </div>
    </div>
</ng-container>


<ng-template #modalListTypes>
    <div class="modal-header">
        <b>Lista delle tipologie</b>
        <div class="card-tools">
            <button type="button" class="btn btn-tool" (click)="closeModalListTypes()" >
              <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <ul class="list-group">
            <li  *ngFor="let t of list" class="list-group-item">
            <div class="row">
                <div class="col-8">
                    {{t.name}}
                </div>
                <div class="col-4">
                    <button class="btn btn-xs btn-secondary ml-1 float-right" (click)="editType(t)"><i class="fa fa-pencil-alt"></i></button>
                    <button class="btn btn-xs btn-danger ml-1 float-right" (click)="removeType(t)"><i class="fa fa-trash"></i></button>
                    
                </div>
            </div>
            </li>
        </ul>
        <button class="btn btn-success btn-block mt-5" (click)="addType()"><i class="fas fa-plus mr-2"></i>Aggiungi</button>
    </div>
</ng-template>