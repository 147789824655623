<section class="content">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==1" (click)="tab=1">
                    <i class="fas fa-sign-out-alt"></i><span class="d-none d-sm-inline-block ml-2">Uscita</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==2" (click)="tab=2">
                    <i class="fas fa-sign-in-alt"></i><span class="d-none d-sm-inline-block ml-2">Ingresso</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==3" (click)="tab=3">
                    <i class="fas fa-exchange-alt"></i><span class="d-none d-sm-inline-block ml-2">Trasferimenti</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==6" (click)="tab=6">
                    <i class="fas fa-clipboard-check"></i><span class="d-none d-sm-inline-block ml-2">Inventario</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" [class.active]="tab==4" (click)="tab=4">
                    <i class="fas fa-chart-line"></i><span class="d-none d-sm-inline-block ml-2">Statistiche</span></a>
            </li>
            <li class="nav-item" *ngIf="user.isSuperUser()">
                <a class="nav-link bg-warning" [class.active]="tab==5" (click)="tab=5">
                    <i class="fas fa-wrench"></i><span class="d-none d-sm-inline-block ml-2">Templates</span></a>
            </li>
        </ul> 
        <div class="tab-content" id="myTabContent" >
            <div class="tab-pane show active" *ngIf="tab==1">
                <app-documentlist (selectedRecordOut)="recordSelected=$event" [modalWindow]="modalWindow" [mode]="mode" [showCard]="false"  [instance]="'sell'" [type]="1"></app-documentlist>
            </div>
            <div class="tab-pane show active" *ngIf="tab==2">
                <app-documentlist (selectedRecordOut)="recordSelected=$event" [modalWindow]="modalWindow" [mode]="mode" [showCard]="false"  [instance]="'buy'" [type]="2"></app-documentlist>
            </div>
            <div class="tab-pane show active" *ngIf="tab==3">
                <app-transfer></app-transfer>
            </div>
            <div class="tab-pane show active" *ngIf="tab==6">
                <app-document-inventory></app-document-inventory>
            </div>
            <div class="tab-pane show active" *ngIf="tab==4">
                <app-documentoverview></app-documentoverview>
            </div>
            <div class="tab-pane show active" *ngIf="tab==5">
                <app-documents-template></app-documents-template>
            </div>
        </div>
</section>
