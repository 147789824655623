<app-windowlist [model]="this">
    <div class="card">
        <div class="row">
            <div class="col-lg-9">
                <div class="input-group input-group-sm p-1">
                    <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="Cerca per nome o username ..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                    <div class="input-group-append ">
                        <button type="submit" class="btn btn-default" data-toggle="tooltip" title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                        <button class="btn btn-default text-danger" data-toggle="tooltip" title="Annulla ricerca" *ngIf="filter_search.value!=''" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <button data-toggle="tooltip" title="Aggiungi" (click)="addUser()" class="btn btn-success btn-sm float-right m-1"><i class="fas fa-plus"></i></button>
                <button data-toggle="tooltip" title="Esporta" (click)="export()"  class="btn btn-secondary btn-sm float-right m-1 "><i class="fas fa-file-export"></i></button>
            </div>
        </div>
        <form [formGroup]="form" *ngIf="list!=null">
            <table class="table table-sm table-head-fixed  text-nowrap m-0" >
                <thead>
                    <th>ID</th>
                    <th>Nome</th>
                    <th>Username</th>
                    <th>Ruolo</th>
                    <th></th>
                </thead>
                <tbody>

                <!-- elemento ripetuto -->
                <ng-container *ngFor="let user of list">
                    <tr  [class.text-gray]="!user.enabled" *ngIf="checkPermission(user);">
                        <td>{{user.id}}</td>
                        <td>{{user.name}}</td>
                        <td><i class="fa fa-solid fa-user mr-1 text-primary"></i>{{user.username}}</td>
                        <td><span class="badge badge-primary">{{user.role}}</span></td>
                        <td class="pr-2">
                            <button type="button" *ngIf="mode!='modal'" data-toggle="tooltip" title="Elimina" class="btn btn-danger btn-xs float-right ml-1" (click)="confirm_delete(user.id)">
                                <i class="fas fa-trash"></i></button>
                            <button type="button" *ngIf="mode!='modal'" class="btn btn-secondary btn-xs float-right ml-1" data-toggle="modal" data-target="#modalUser" (click)="setUser(user)">
                                <i class="fas fa-pencil-alt"></i></button>
                            
                            <button type="button"  *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right" (click)="selectRecord(user);">
                                <span class="d-none d-sm-inline-block m-1">Seleziona</span><i class="fas fa-chevron-right"></i>
                            </button>
                        </td>
                    </tr>
                </ng-container>

                </tbody>
            </table>
        </form>
        <div class="card-footer clearfix py-1">
            <!-- <small class="mr-1">Visualizzati <b class="text-primary" style="font-size: 110%;">{{recordcount}}</b> elementi</small> -->
            <app-pagination [model]="this" ></app-pagination>
        </div>
    </div>
</app-windowlist>

<ng-template #modalUser>
        <div class="modal-header py-1 bg-primary">
            <b>Impostazione utente</b>
        </div>
        <div class="modal-body" *ngIf="selectedUser">
            <div class="row">
                <div class="col-lg-12 mb-2">
                    <div class="form-group form-group-sm">
                        <label>Nome</label>
                        <input type="text" class="form-control form-control-sm" placeholder="inserisci nome..."  [(ngModel)]="selectedUser.name" >
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group form-group-sm">
                        <label>Username <small>(Email)</small></label>
                        <div class="input-group input-group-sm">
                            <input type="email" class="form-control  form-control-sm" placeholder="inserisci email..."  [(ngModel)]="selectedUser.username" >
                            <div class="input-group-append input-group-append-sm">
                                <span class="input-group-text input-group-text-sm"><i class="fas fa-user"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group form-group-sm">
                        <label>Password</label>
                        <div class=" input-group input-group-sm">
                            <input *ngIf="!currentUser.isSuperUser()" type="password" class="form-control form-control-sm" placeholder="inserisci password..."  [(ngModel)]="selectedUser.password">
                            <input *ngIf="currentUser.isSuperUser()" type="text" class="form-control form-control-sm" placeholder="inserisci password..."  [(ngModel)]="selectedUser.password">
                            <div class="input-group-append input-group-append-sm">
                                <span class="input-group-text input-group-text-sm"><i class="fa fa-key"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <div class="form-group form-group-sm">
                        <label>Ruolo</label>
                        <select class="custom-select custom-select-sm" name="role" [(ngModel)]="selectedUser.role">
                            <option value="admin" *ngIf="currentUser.isAdmin() || currentUser.isSuperUser()">Amministratore</option>
                            <option value="manager" *ngIf="currentUser.isAdmin() || currentUser.isSuperUser()">Manager</option>
                            <option value="agent">Agente</option>
                            <option value="external">Partner</option>
                            <option value="guest">Ospite</option>
                        </select>
                        <small>
                            <i *ngIf="selectedUser.role=='admin'"     >*<b>Amministratore</b>: ha accesso a tutte le sezioni ed autorizzazioni complete.</i>
                            <i *ngIf="selectedUser.role=='manager'"   >*<b>Manager</b>: ha accesso solo alle sezioni assegnategli dall'Amministratore ed autorizzazioni complete.</i>
                            <i *ngIf="selectedUser.role=='agent'"     >*<b>Agente</b>: ha accesso solo alle sole sezioni assegnategli dall'Amministratore ed autorizzazioni limitate (visualizzazione, inserimento, modifica).</i>
                            <i *ngIf="selectedUser.role=='external'"  >*<b>Partner</b>: ha accesso limitato solo alle sezioni assegnategli dall'Amministratore ed autorizzazioni minime (visualizzazione, inserimento).</i>
                            <i *ngIf="selectedUser.role=='guest'"     >*<b>Ospite</b>: non ha accesso alla piattaforma di gestione ma solo eventualmente a quella riservata ai clienti registrati.</i>
                        </small>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="form-group form-group-sm">
                        <label>Stato</label>
                        <select class="custom-select custom-select-sm" name="enabled" [(ngModel)]="selectedUser.enabled">
                            <option value="1">Abilitato</option>
                            <option value="0">Disabilitato</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-6" *ngIf="currentUser.isSuperUser()">
                    <div class="form-group form-group-sm">
                        <label>ID Anagrafica</label>
                        <div class="input-group input-group-sm">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="selectedUser.id_address">
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="isArray(selectedUser.params)">
                <div  *ngFor="let p of selectedUser.params" [class.col-3]="p.type=='number' || p.type=='boolean'"  [class.col-4]="p.type=='text'" [class.col-12]="p.type=='table'">
                    <label>{{p.label}}</label>
                    <input *ngIf="p.type=='number'" type="number" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                    <input *ngIf="p.type=='text'" type="text" class="form-control form-control-sm" name="value" [(ngModel)]="p.value" />
                    <select *ngIf="p.type=='boolean'" class="form-control form-control-sm" name="value" [(ngModel)]="p.value">
                        <option value="true">Si</option>
                        <option value="false">No</option>
                    </select>
                    <ngx-tags-input *ngIf="p.type=='multiple'" displayField="item" class="form-control form-control-sm" [(ngModel)]="p.value" name="value"></ngx-tags-input>
                    <table *ngIf="p.type=='table'" class="table table-nowrap  table-sm table-head-fixed table-hover m-0 text-sm">
                        <thead class="bg-secondary">
                            <th *ngFor="let f of p.fields">{{f.label}}</th>
                            <th></th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let v of p.value">
                                <td *ngFor="let f of p.fields">
                                    <input class="form-control form-control-sm" type="text" name="{{f.name}}" [(ngModel)]="v[f.name]"/>
                                </td>
                                <td>
                                    <button type="button" class="btn btn-xs btn-danger" (click)="removeTableRecord(p,v);"><i class="fa fa-trash"></i></button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td><button type="button" class="btn btn-xs btn-success" (click)="addTableRecord(p);"><i class="fa fa-plus"></i></button></td>
                            </tr>

                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-success"  (click)="confirmUser()"><i class="fas fa-save mr-1"></i>Conferma</button>
            <button type="button" class="btn btn-secondary" (click)="cancelUser()"  >Annulla</button>
        </div>
</ng-template>