<select class="custom-select custom-select-sm"  name="{{name}}" [(ngModel)]="value" (ngModelChange)="onChange()"  >
    <!--
    <option value="addresses"            >Anagrafica<i class="fa fa-user float-right text-light"></i></option>
    <option value="contacts"             >Contatti<i class="fa fa-address-card float-right text-light"></i></option>
    <option value="installations"        >Installazioni<i class="fa fa-cash-register float-right text-light"></i></option>
    <option value="products"        >Prodotti<i class="fa fa-cash-register float-right text-light"></i></option>
    
    <option value="contracts"            >Contratti<i class="fa fa-file-signature float-right text-light"></i></option>
    <option value="contractsinstallments">Rata contratti</option>
    <option value="shops"                >Esercizi<i class="fa-store-alt fas float-right text-light"></i></option>
    <option value="cashflow"             >Movimenti<i class="fa fa-dollar-sign float-right text-light"></i></option>
    <option value="documents"             >Documenti<i class="fa fa-file-invoice-dollar float-right text-light"></i></option>
    <option value="inventories"             >Magazzino<i class="fa fa-file-invoice-dollar float-right text-light"></i></option>
    
    <option value="logusers"    >Log Operatori<i class="fa fa-file-invoice-dollar float-right text-light"></i></option>
    
    <option value="deadlines"            >Agenda<i class="fa fa-calendar-alt float-right text-light"></i></option>
    <option value="activities"           >Attività<i class="fa fa-tasks float-right text-light"></i></option>
    <option value="booking"           >Prenotazioni<i class="fa fa-tasks float-right text-light"></i></option>
    <option value="tickets"           >Biglietti<i class="fa fa-tasks float-right text-light"></i></option>
    <option value="formsvalue"           >Moduli<i class="fa fa-tasks float-right text-light"></i></option>
    <option value="users"           >Utenti<i class="fa fa-users float-right text-light"></i></option>
        -->

    <option *ngFor="let t of tables" value="{{t.table}}">{{t.label}}</option> 
    <option value="" *ngIf="show_all"><i class=""></i>TUTTE</option>
</select>
