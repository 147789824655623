<app-window [title]="title">
    <form role="form" id="detailForm">
       
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="home-tab" data-toggle="tab" href="#tabActivity1" role="tab" aria-controls="home" aria-selected="true">
                    <i class="fas fa-tasks"></i><span class="d-none d-sm-inline-block ml-2">Dettagli</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabActivity4" role="tab" aria-controls="profile" aria-selected="false" >
                    <i class="fas fa-clipboard"></i><span class="d-none d-sm-inline-block ml-2">Annotazione <span *ngIf="record.customfields!=undefined && record.customfields.length>0"  class="badge badge-danger ml-1">{{record.customfields.length}}</span></span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabActivity5" role="tab" aria-controls="profile" aria-selected="false" >
                    <i class="fas fa-map"></i><span class="d-none d-sm-inline-block ml-2">Ubicazione <span *ngIf="record.latitude==0"  class="badge badge-danger ml-1">NO GPS</span></span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabActivity3" role="tab" aria-controls="profile" aria-selected="false" >
                    <i class="fas fa-euro-sign"></i><span class="d-none d-sm-inline-block ml-2">Pagamento <span *ngIf="record.amount>0 || record.paid>0"  class="badge badge-danger ml-1"><i class="fas fa-coins"></i></span></span></a>
            </li>
            <li class="nav-item" *ngIf="missingValuesCount>0">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tabActivity2" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-puzzle-piece"></i>
                    <span class="d-none d-sm-inline-block ml-2">Completamento dati <span  class="badge badge-danger ml-1">{{missingValuesCount}}</span></span>
                </a>
            </li>
        </ul>

        <div class="p-2" >
            <div class="tab-content" id="myTabContent">
                <!-- DETTAGLI -->
                <div class="tab-pane fade show active" id="tabActivity1" role="tabpanel" aria-labelledby="tab1-tab">
            
                    <div class="row">
                        <div class="col-sm-5">
                            <div class="form-group">
                                <label>Data</label>
                                <div class="input-group" >
                                    <input class="form-control" type="date"  name="date"  [(ngModel)]="record.date"  />
                                    <div class="input-group-append" *ngIf="id>0">
                                        <input class="form-control" type="text"  name="time"  [(ngModel)]="record.time"  />
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <app-type [id_type]="record.id_type"  (onTypeSelected)="type_activity_selected=$event;" [table]="'activities'" [record]="record" ></app-type>

                            </div>
                        </div>
                        <div class="col-sm-3">
                            <div class="form-group">
                                <label>Fase</label>
                                <select class="custom-select" [(ngModel)]="record.phase" name="phase" #phase="ngModel" required>
                                    <option value="1">In corso</option>
                                    <option value="2">Conclusa</option>
                                    <option value="3">Confermati</option>
                                </select>
                                
                                <div *ngIf="phase.invalid && (phase.dirty || phase.touched)" class="alert alert-danger">
                                    <div *ngIf="phase.errors.required">Campo obbligatorio.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-1">
                            <div class="form-group">
                                <label>Priorità</label>
                                <select class="custom-select" [(ngModel)]="record.priority" name="priority" #priority="ngModel" required>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Operatore</label>
                                <select class="custom-select" [(ngModel)]="record.id_user" name="id_user" >
                                    <ng-container *ngFor="let u of users">
                                        <option *ngIf="u.enabled" [ngValue]="u.id">{{u.username}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-5">
                            <div class="form-group">
                                <label>Elemento collegato</label>
                                <div class="input-group">
                                    <p class="border p-2">
                                        <app-reference #referenceRecord [showConnectBtn]="true" (recordConnected)="connectActivity($event)" [type_filter]="type_activity_selected" [reference]="record.reference" [table]="record.table" [mode]="'detail'"></app-reference>
                                    </p>
                                </div> 
                            </div>
                        </div>
                        <!--<div class="col-sm-5">
                            <div class="form-group">
                                <label>Agenda</label>
                                <div class="input-group">
                                    <p class="border p-2" [(ngModel)]="record.id_table" *ngIf="record.id_deadline>0"><span  ><strong>{{record.deadline.date}}</strong><br/><i>{{record.reference.reference}}</i><br/><small>{{record.reference.address}}, {{record.reference.city | uppercase}}</small></span></p>
                                    
                                    <div class="callout callout-info" *ngIf="record.id_deadline==0">
                                        <h5><i class="icon fas fa-info mr-2"></i>Nota:</h5>
                                        <i>Nessun voce di agenda selezionata</i>
                                    </div>
                                    <div class=" ml-2 ">
                                        <button data-toggle="modal" data-target="#modal" class="btn btn-default bg-blue" (click)="openModal('DeadlinesComponent','Seleziona la voce di agenda','id_table','deadlines')">
                                            <span class="d-none d-sm-inline-block">Seleziona</span><i class="nav-icon fas fa-cash-register ml-2"></i></button>
                                    </div>
                                </div> 
                            </div>
                        </div>-->
                        <div class="col-sm-7">
                            <div class="form-group">
                                <label>Descrizione</label>
                                <textarea class="form-control" rows="2" onblur="this.rows=2;" onfocus="this.rows=4;"  name="description" [(ngModel)]="record.description"></textarea>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="form-group">
                                <label>Note</label>
                                <div class="input-group">
                                    <textarea  class="form-control" rows="1" onblur="this.rows=1;" onfocus="this.rows=4;"  name="note" [(ngModel)]="record.note" placeholder="..."></textarea>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ANNOTAZIONE -->
                <div class="tab-pane fade" id="tabActivity4" role="tabpanel" aria-labelledby="tab4-tab">
                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-info" *ngIf="record.id_form>0">
                                È stato compilato un modulo
                                <button class="btn btn-info" (click)="openForm()">Vedi modulo</button>
                            </div>
                        </div>
                    </div>
                    <!-- Extra fields -->
                    <app-customfields [list]="record.customfields" [table]="'activities'" [id_table]="record.id" [condition_field]="'id_type'" [condition_id]="record.id_type" ></app-customfields>
                </div>
                <!-- COMPLETAMENTO DATI -->
                <div class="tab-pane fade" id="tabActivity2" role="tabpanel" aria-labelledby="tab2-tab">
                    <app-missing-values [list]="record.missingValues"></app-missing-values>
                </div>
                <!-- PAGAMENTO -->
                <div class="tab-pane fade" id="tabActivity3" role="tabpanel" aria-labelledby="tab3-tab">
                    <div class="row">
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Importo</label>
                                <div class="input-group">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text">&euro;</span>
                                    </div>
                                    <input type="number" class="form-control"  min="0" max="999999999" step="0.01" name="amount" placeholder="..." [(ngModel)]="record.amount" (change)="calculateGross()" (keyup)="calculateGross()">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-2">
                            <div class="form-group">
                                <label>IVA</label>
                                <div class="input-group">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text">%</span>
                                    </div>
                                    <input type="number" class="form-control"  min="0" max="100" step="1" name="tax" placeholder="..." [(ngModel)]="record.tax" >
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Totale</label>
                                <div class="input-group">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text">&euro;</span>
                                    </div>
                                    <input type="number" class="form-control"  min="0" max="999999999" step="0.01" name="gross" placeholder="..." [(ngModel)]="record.gross" (change)="calculateNet()" (keyup)="calculateNet()">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label>Incassato</label>
                                <div class="input-group">
                                    <div class="input-group-prepend ">
                                        <span class="input-group-text">&euro;</span>
                                    </div>
                                    <input type="number" class="form-control"  min="0" max="999999999" step="0.01" name="paid" placeholder="..." [(ngModel)]="record.paid" >
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-8">
                            <div class="form-group">
                                <label>Metodo di pagamento</label>
                                <div class="input-group">
                                    <input type="text" class="form-control"   name="method_payment"  [(ngModel)]="record.method_payment" >
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <label>Causale</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" min="0" step="0.01" name="paid_description" placeholder="..." [(ngModel)]="record.paid_description " >
                                </div>
                            </div>
                        </div>
                       
                        <div class="col-sm-12">
                            <label>Stato </label>
                            <div class="btn-group mb-2" role="group" >
                                <button type="button" class="btn btn-success" [class.active]="record.paid_type==1" (click)="record.paid_type=1">Pagato</button>
                                <button type="button" class="btn btn-secondary" [class.active]="record.paid_type==2" (click)="record.paid_type=2">Omaggio</button>
                                <button type="button" class="btn btn-primary" [class.active]="record.paid_type==3" (click)="record.paid_type=3">Rischedula</button>
                            </div>
                                <ng-container *ngIf="record.paid_type==3">
                                <div class="form-group">
                                    <label>Data del pagamento</label>
                                    <input class="form-control form-control-sm" type="date" name="reschedule_payment" [(ngModel)]="record.reschedule_payment">
                                </div>
                                <div class="form-group">
                                    <label>Note</label>
                                    <input class="form-control form-control-sm" type="text" name="note_reschedule_payment" [(ngModel)]="record.note_reschedule_payment">
                                </div>
                            </ng-container>
                        </div>
                        
                        <div class="col-sm-12 border-top mt-3 pt-2">
                            <h5>Elenco movimenti collegati</h5>
                            <app-cash-flow #cashflow  [deleteInRow]="true" [filterbox]="false" [id_table]="record.id" [table]="'activities'" [mode]="'embedded'" *ngIf="id != 0"></app-cash-flow>
                            
                            <ng-container *ngIf="record.id_cashflow > 0">
                                <div class="input-group">
                                    <!--<button class="btn btn-sm btn-secondary" (click)="openCashFlow(record.id_cashflow);">Vedi movimento</button>-->
                                    <button class="btn btn-sm btn-danger mr-2" (click)="record.id_cashflow=0">Rimuovi collegamento</button>
                                </div>
                            </ng-container>
                            
                            <ng-container *ngIf="!record.id_cashflow || record.id_cashflow == 0">
                                <div class="input-group">
                                    <button class="btn btn-primary btn-sm" (click)="addPayment()">Registra pagamento</button>
                                    <button class="btn btn-primary btn-sm ml-2" (click)="LinkPayment()">Collega pagamento</button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <!-- UBICAZIONE -->
                <div class="tab-pane fade" id="tabActivity5" role="tabpanel" aria-labelledby="tab5-tab">
                    <app-cities name="city"  [enable_geocoding]="false" [latitude]="record.latitude" [longitude]="record.longitude" [address]="record.address" [country]="record.country" [city]="record.city"  [zip]="record.zip" (onEntrySelected)="record.address=$event.address;record.city=$event.city;record.country=$event.country;record.zip=$event.zip;"></app-cities>
                    <div class="row mt-2">
                        <div class="col-5">
                            <div class="form-group row">
                                <div class="col-5"><label>Latitudine</label></div>
                                <div class="col-7">
                                    <div class="input-group">
                                        <input type="text" class="form-control form-control-sm" name="city" placeholder=" ..." [(ngModel)]="record.latitude" (ngModelChange)="verifyCoordinate()" >
                                        <div class="input-group-append bg-light">
                                            <div class="input-group-text" data-toggle="tooltip" title="Puoi Incollare direttamente qui le coordinate nel formato: LAT, LONG">
                                                <span class="fas fa-info"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="form-group row">
                                <div class="col-5 text-right"><label>Longitudine</label></div>
                                <div class="col-7">
                                    <input type="text" class="form-control form-control-sm" name="zip" placeholder=" ..." [(ngModel)]="record.longitude" (ngModelChange)="verifyCoordinate()" >
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <button  class="btn btn-sm btn-default btn-block" (click)="openSearchGPS()" ><i class="fas fa-search-location mr-2"></i>Trova</button>                                    
                        </div>
                    </div>                     
                </div>
            </div>
            
        </div>
    </form>
    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>



