import { Address } from "./address";

export class Project{
    id:number;
    name:string;
    description:string;
    addressItem:Address;
    id_address:number;
    id_type:number;
    date:string;
    amount:number;
    note:string;
    phase:number;
    files:File[];


}