<app-window [title]="title" >
    <form role="form" id="detailForm">
        
        <!-- TAB GENERALI-->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item ">
                <a class="nav-link active" id="profile-tab" data-toggle="tab" href="#tab2" role="tab" aria-controls="profile" aria-selected="true">
                    <i class="fas fa-address-card"></i><span class="d-none d-sm-inline-block ml-2">Indirizzo e Fatturazione</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab3" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-info"></i><span class="d-none d-sm-inline-block ml-2">Altri dati</span></a>
            </li>
            <!-- <li class="nav-item">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab4" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-mail-bulk"></i><span class="d-none d-sm-inline-block ml-2">Contatti<span *ngIf="record.contacts && record.contacts.length>0" class="badge badge-warning ml-1">{{record.contacts.length}}</span></span></a>
            </li> -->
            <li class="nav-item" *ngIf="record.id">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab5" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="far fa-sticky-note"></i><span class="d-none d-sm-inline-block ml-2">Note<span *ngIf="record.notes && record.notes.length>0" class="badge badge-warning ml-1">{{record.notes.length}}</span></span></a>
            </li>
            <li class="nav-item" *ngIf="record.id">
                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#tab6" role="tab" aria-controls="profile" aria-selected="false">
                    <i class="fas fa-hdd"></i><span class="d-none d-sm-inline-block ml-2">Files<span class="badge badge-warning" *ngIf="record.files && record.files.length>0">{{record.files.length}}</span></span></a>
            </li>
        </ul>
        <div class="p-2" style="min-height:200px;">
            <div class="tab-content" id="myTabContent">
                <!-- INDIRIZZO E FATTURAZIONE -->
                <div class="tab-pane fade show active" id="tab2" role="tabpanel" aria-labelledby="tab2-tab">
                    <div class="form-group row">
                        <div class="col-md-2"><label>Ragione sociale / Nominativo</label></div>
                        <div class="col-md-10">
                            <input autofocus required type="text" class="form-control form-control-sm" name="name" placeholder="..."
                            #name="ngModel" [(ngModel)]="record.name">
                            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
                                <div *ngIf="name.errors.required">Campo obbligatorio.</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-2"><label>Tipologia</label></div>
                        <div class="col-md-4">
                            <select class="form-control form-control-sm" [(ngModel)]="record.id_type" name="id_type" #id_type="ngModel" required>
                                <option *ngFor="let t of type_address" [ngValue]="t.id">{{t.name}}</option>
                            </select>
                            <div *ngIf="id_type.invalid && (id_type.dirty || id_type.touched)" class="alert alert-danger">
                                <div *ngIf="id_type.errors.required">Campo obbligatorio.</div>
                            </div>
                        </div>
                        <!-- <div class="d-none d-md-block col-1 pl-0"><i class="fas fa-circle fa-2x"
                                    [class.text-navy]="record.id_type==1"
                                    [class.text-olive]="record.id_type==2" 
                                    [class.text-purple]="record.id_type==3" 
                                    [class.text-lightblue]="record.id_type==4"
                                    [class.text-pink]="record.id_type==5"
                                    [class.text-teal]="record.id_type==6" ></i>
                        </div> -->
                        <div class="col-md-2 px-md-0 text-md-right"><label>Stato</label></div>
                        <div class="col-md-3">
                            <select class="form-control form-control-sm" [(ngModel)]="record.status" name="status" #id_type="ngModel" required>
                                <option value="1">Attivi</option>
                                <option value="2">Cestinati</option>
                                <option value="3">Non attivi</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row mb-md-1">
                        <div class="col-md-2">
                            <label>Residente in</label>
                        </div>
                        <div class="col-10 col-md-5">
                            <app-nations [class]="'form-control form-control-sm'" [nation]="record.nation" (onSelect)="onChangeNation($event);"></app-nations>
                        </div>
                        <div class="col-2 col-md-5 text-md-right">
                            <a  class="btn btn-sm btn-default" (click)="openSearchGPS()" ><i class="fas fa-search-location mr-2"></i><span class="d-none d-md-inline-block ml-2">Trova</span></a>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-12">
                            <app-cities name="city" [address]="record.address" [country]="record.country" [city]="record.city"  [zip]="record.zip" (onEntrySelected)="record.address=$event.address;record.city=$event.city;record.country=$event.country;record.zip=$event.zip;"></app-cities>
                        </div>
                    </div>
                    <div class="form-group row mb-1">
                        <div class="col-md-2">
                            <label>Partita IVA</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <app-vat-number [vat_number]="record.vat_number" (onTextChange)="record.vat_number=$event"></app-vat-number>
                                <div class="input-group-prepend show">
                                    <button type="button" class="btn btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="true"></button>
                                    <ul class="dropdown-menu" x-placement="bottom-start">
                                        <li class="dropdown-header"><b>Suggerisci...</b></li>
                                        <li class="dropdown-divider"></li>
                                        <!-- <li class="dropdown-item" (click)="onChangeTypeVatNumber('1')"><a>Italia</a></li> -->
                                        <li class="dropdown-item" (click)="onChangeTypeVatNumber('2')"><a>Unione Europea</a></li>
                                        <li class="dropdown-item" (click)="onChangeTypeVatNumber('3')"><a>Extra UE</a></li>
                                        <li class="dropdown-item" (click)="onChangeTypeVatNumber('4')"><a>Privato estero</a></li>
                                    </ul>
                                </div>
                            </div> 
                        </div>
                        <div class="col-md-2 px-md-0 text-md-right">
                            <label>Codice fiscale</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <app-tax-code [tax_code]="record.tax_code" (onTextChange)="record.tax_code=$event"></app-tax-code>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-2">
                            <label>SDI</label>
                        </div>
                        <div class="col-6 col-md-2">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm" [class.is-invalid]="record.sdi.length && record.sdi.length < 6 && record.sdi.length > 7"
                                name="sdi" [(ngModel)]="record.sdi" (ngModelChange)="record.sdi = $event.toUpperCase()" >
                            </div>
                        </div>
                        <div class="col-md-4 px-md-0 text-md-right">
                            <label>PEC</label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group">
                                <input type="email" class="form-control form-control-sm"  name="pec" [(ngModel)]="record.pec"  data-toggle="tooltip">
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-md-2"><label>Contatti</label></div>
                        <div class="col-md-10">
                            <app-contact [list]="record.contacts" [table]="'addresses'" ></app-contact>
                        </div>
                    </div>
                </div>
                <!-- ALTRI DATI -->
                <div class="tab-pane " id="tab3" role="tabpanel" aria-labelledby="tab3-tab">
                    <div class="form-group row mt-2" >
                        <div class="col-2"><label>Utente</label></div>
                        <div class="col-10">
                            <div class="input-group">
                                <input type="text" class="form-control form-control-sm" readonly name="user" data-toggle="tooltip" title="ID: {{record.id_user}}" [(ngModel)]="record.username">
                                <div class="input-group-append">
                                    <button class="btn btn-info btn-sm" (click)="searchUser();"><i class="fa fa-user-tie"></i></button>
                                    <button *ngIf="record.id_user>0" class="btn btn-danger btn-sm" (click)="unlinkUser()">
                                        <i class="fa fa-unlink"></i>
                                    </button>
                                </div>
                            </div>
                            <small><i>Associa l'anagrafica ad un <b>utente</b> della piattaforma (persona in grado di accedere alla piattaforma mediante delle credenziali).</i></small>
                        </div>
                    </div>
                    <app-customfields [list]="record.customfields" [table]="'addresses'" [id_table]="record.id" [condition_id]="record.id_type" [condition_field]="'type'"></app-customfields>
                </div>
                <!-- CONTATTI 
                <div class="tab-pane " id="tab4" role="tabpanel" aria-labelledby="tab4-tab">
                    <app-contact [list]="record.contacts" [table]="'addresses'" ></app-contact>
                </div> -->
                <!-- NOTE -->
                <div class="tab-pane " id="tab5" role="tabpanel" aria-labelledby="tab5-tab" *ngIf="record.id">
                    <app-notebox [list]="record.notes" [table]="'addresses'" [id_table]="record.id" [id_address]="record.id" *ngIf="record.id"></app-notebox>
                </div>
                <!-- FILE -->
                <div class="tab-pane " id="tab6" role="tabpanel" aria-labelledby="tab6-tab" *ngIf="record.id">
                    <app-files [files]="record.files" [directory]="'addresses/'+id"></app-files>
                </div>
            </div>
        </div>

        <!-- SEZIONE INFERIORE -->
        <div class="row pb-4 m-1 bg-light border" *ngIf="id != 0">
            <div class="col-12 pb-2"><b class="text-gray text-xs">ELEMENTI COLLEGATI</b></div>
            <div class="col-2 px-0">
                <small>
                <div class="nav flex-column nav-pills" id="custom-tabs-four-tab" role="tablist">
                    <ng-container *ngIf="id != 0 && isModuleEnabled('cash-flow')">
                        <a class="nav-link py-1" id="custom-tabs-1-tab" data-toggle="pill" href="#custom-tabs-1" 
                        role="tab" aria-controls="custom-tabs-1" aria-selected="true">
                            <i class="nav-icon fas fa-dollar-sign"></i><span class="d-none d-sm-inline-block ml-2"><span class="d-none d-lg-block">Movimenti</span></span></a>
                    </ng-container>
                    <ng-container  *ngIf="id != 0 && isModuleEnabled('deadlines')">
                        <a class="nav-link py-1" id="custom-tabs-2-tab" data-toggle="pill" href="#custom-tabs-2" 
                        role="tab" aria-controls="custom-tabs-2" aria-selected="true">
                        <i class="nav-icon far fa-calendar-alt"></i><span class="d-none d-sm-inline-block ml-2"><span class="d-none d-lg-block">Agenda</span></span></a>
                    </ng-container>
                    <ng-container  *ngIf="id != 0 && isModuleEnabled('activities')">
                        <a class="nav-link py-1" id="custom-tabs-8-tab" data-toggle="pill" href="#custom-tabs-8" 
                        role="tab" aria-controls="custom-tabs-8" aria-selected="true">
                        <i class="nav-icon fas fa-tasks"></i><span class="d-none d-sm-inline-block ml-2"><span class="d-none d-lg-block">Attività</span></span></a>
                    </ng-container>
                    <ng-container *ngIf="id != 0 && isModuleEnabled('documents')">
                        <a class="nav-link py-1" id="custom-tabs-3-tab" data-toggle="pill" href="#custom-tabs-3" 
                        role="tab" aria-controls="custom-tabs-3" aria-selected="true">
                        <i class="nav-icon fas fa-file-invoice-dollar"></i><span class="d-none d-sm-inline-block ml-2"><span class="d-none d-lg-block">Documenti</span></span></a>
                    </ng-container>
                    <ng-container *ngIf="id != 0 && isModuleEnabled('shops')">
                        <a class="nav-link py-1" id="custom-tabs-4-tab" data-toggle="pill" href="#custom-tabs-4" 
                        role="tab" aria-controls="custom-tabs-4" aria-selected="true">
                        <i class="fas fa-store-alt"></i><span class="d-none d-sm-inline-block ml-2"><span class="d-none d-lg-block">Esercizi</span></span></a>
                    </ng-container>
                    <ng-container *ngIf="id != 0 && isModuleEnabled('installations')">
                        <a class="nav-link py-1" id="custom-tabs-5-tab" data-toggle="pill" href="#custom-tabs-5" 
                        role="tab" aria-controls="custom-tabs-5" aria-selected="true">
                        <i class="nav-icon fas fa-cash-register"></i><span class="d-none d-sm-inline-block ml-2"><span class="d-none d-lg-block">Prodotti</span></span></a>
                    </ng-container>
                    <ng-container *ngIf="id != 0 && isModuleEnabled('contracts')">
                        <a class="nav-link py-1" id="custom-tabs-6-tab" data-toggle="pill" href="#custom-tabs-6" 
                        role="tab" aria-controls="custom-tabs-6" aria-selected="true">
                        <i class="nav-icon fas fa-file-signature"></i><span class="d-none d-sm-inline-block ml-2"><span class="d-none d-lg-block">Contratti</span></span></a>
                    </ng-container>
                    <ng-container *ngIf="id != 0 && isModuleEnabled('notifications')">
                        <a class="nav-link py-1" id="custom-tabs-7-tab" data-toggle="pill" href="#custom-tabs-7" 
                        role="tab" aria-controls="custom-tabs-7" aria-selected="true">
                        <i class="nav-icon far fa-paper-plane"></i><span class="d-none d-sm-inline-block ml-2"><span class="d-none d-lg-block">Notifiche</span></span></a>
                    </ng-container>
                </div>
            </small>
            </div>
            <div class="col-10">
                <div class="tab-content" id="custom-tabs-four-tabContent">
                    <!-- TAB: Movimenti -->
                    <div *ngIf="isModuleEnabled('cash-flow')" class="tab-pane active" id="custom-tabs-1" role="tabpanel" aria-labelledby="custom-tabs-1-tab">
                        <app-cash-flow [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-cash-flow>
                    </div>
                    <!-- TAB: Scadenze -->
                    <div *ngIf="isModuleEnabled('deadlines')" class="tab-pane" id="custom-tabs-2" role="tabpanel" aria-labelledby="custom-tabs-2-tab">
                        <app-deadlines [viewMode]="'table'" [filter_box]="false" [default_filterdate]="100" [no_dateend]="true" [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-deadlines>
                    </div>
                    <!-- TAB: Attività -->
                    <div *ngIf="isModuleEnabled('activities')" class="tab-pane" id="custom-tabs-8" role="tabpanel" aria-labelledby="custom-tabs-8-tab">
                        <app-activities-list [default_filterdate]="100" [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-activities-list>
                    </div>
                    <!-- TAB: Fatture -->
                    <div *ngIf="isModuleEnabled('documents')" class="tab-pane" id="custom-tabs-3" role="tabpanel" aria-labelledby="custom-tabs-3-tab">
                        
                        
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="nav-item" >
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#dd-1" role="tab" aria-controls="profile" aria-selected="false">
                                    Documenti
                                </a>
                            </li>
                            <li class="nav-item" >
                                <a class="nav-link" id="profile-tab" data-toggle="tab" href="#dd-2" role="tab" aria-controls="profile" aria-selected="false">
                                    Beni
                                </a>
                            </li>
                            
                        </ul>
                        <div class="tab-content" id="custom-tabs-four-tabContent">
                            <div  class="tab-pane active" id="dd-1" role="tabpanel" aria-labelledby="dd-1">
                                <app-documentlist [filterbox]="false" [type]="0" [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0" (ids)="ids_documents=$event"></app-documentlist>
                            </div>
                            <div  class="tab-pane" id="dd-2" role="tabpanel" aria-labelledby="dd-2">
                                <app-documentinventories [ids_documents]="ids_documents" [mode]="'embedded'" *ngIf="id != 0"></app-documentinventories>
                            </div>
                        </div>
                       
                    </div>
                    <!-- TAB: Esercizi -->
                    <div *ngIf="isModuleEnabled('shops')" class="tab-pane" id="custom-tabs-4" role="tabpanel" aria-labelledby="custom-tabs-4-tab">
                        <app-shops [id_address]="record.id" [mode]="'embedded'" *ngIf="record.id != 0" (firstIdShop)="first_id_shop=$event"></app-shops>
                    </div>
                    <!-- TAB: Prodotti -->
                    <div *ngIf="isModuleEnabled('installations')" class="tab-pane" id="custom-tabs-5" role="tabpanel" aria-labelledby="custom-tabs-5-tab">
                        <app-serialshop [id_address]="id" [id_shop]="first_id_shop" [mode]="'embedded'" *ngIf="id != 0"></app-serialshop>
                    </div>
                    <!-- TAB: Contratti -->
                    <div *ngIf="isModuleEnabled('contracts')" class="tab-pane" id="custom-tabs-6" role="tabpanel" aria-labelledby="custom-tabs-6-tab">
                        <app-contracts [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-contracts>
                    </div>
                    <!-- TAB: Notifiche -->
                    <div *ngIf="isModuleEnabled('notifications')" class="tab-pane" id="custom-tabs-7" role="tabpanel" aria-labelledby="custom-tabs-7-tab">
                        <app-notificationlogs [id_address]="record.id" [mode]="'embedded'" *ngIf="id != 0"></app-notificationlogs>
                    </div>
                </div>
            </div>
        </div>
    
    </form>
    <app-toolbarmodel [model]="this"></app-toolbarmodel>
</app-window>


<ng-template #modalAddUser>
    <div class="modal-body">
        <h4 class="text-center text-primary">Vuoi creare un utente collegato a questa anagrafica<i class="fas fa-question"></i></h4>
        <hr>
        <div class="form-group">
            <label>Nome</label>
            <input type="text" class="form-control form-control-sm" name="newuser_name" [(ngModel)]="newuser.name">
            <br>
            <label>Username</label>
            <input type="text" class="form-control form-control-sm" name="newuser_username" [(ngModel)]="newuser.username">
            <br>
            <label>Password</label>
            <input type="password" class="form-control form-control-sm" name="newuser_password" [(ngModel)]="newuser.password">
            <br>
            <label>Ruolo</label>
            <select class="form-control form-control-sm" name="newuser_role" [(ngModel)]="newuser.role">
                <option value="admin" *ngIf="currentUser.isAdmin() || currentUser.isSuperUser()">Amministratore</option>
                <option value="manager" *ngIf="currentUser.isAdmin() || currentUser.isSuperUser()">Manager</option>
                <option value="agent">Agente</option>
                <option value="external">Partner</option>
                <option value="guest">Ospite</option>
            </select>
            <small>
                <i *ngIf="newuser.role=='admin'"     >*<b>Amministratore</b>: ha accesso a tutte le sezioni ed autorizzazioni complete.</i>
                <i *ngIf="newuser.role=='manager'"   >*<b>Manager</b>: ha accesso solo alle sezioni assegnategli dall'Amministratore ed autorizzazioni complete.</i>
                <i *ngIf="newuser.role=='agent'"     >*<b>Agente</b>: ha accesso solo alle sole sezioni assegnategli dall'Amministratore ed autorizzazioni limitate (visualizzazione, inserimento, modifica).</i>
                <i *ngIf="newuser.role=='external'"  >*<b>Partner</b>: ha accesso limitato solo alle sezioni assegnategli dall'Amministratore ed autorizzazioni minime (visualizzazione, inserimento).</i>
                <i *ngIf="newuser.role=='guest'"     >*<b>Ospite</b>: non ha accesso alla piattaforma di gestione ma solo eventualmente a quella riservata ai clienti registrati.</i>
            </small>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-sm btn-secondary" (click)="closeModalAddUser();">No, grazie</button>
        <button class="btn btn-sm btn-success" (click)="createUser();">Crea utente</button>
    </div>
</ng-template>