<ng-container *ngIf="product">
    <span class="badge badge-secondary mr-1">{{product.type}}</span><span *ngIf="product.codeinternal" class="badge bg-gray mr-1">{{product.codeinternal}}</span><br/>
    <span (click)="selected()">
        {{product.brand}} <strong>{{product.name}}</strong>
        <div class="badge badge-info ml-2" *ngIf="sn">{{sn}}</div>
        <i class="fas fa-desktop ml-2" data-toggle="tooltip" title="scheda web" *ngIf="product.title"></i>
    </span>
    <i (click)="showSerials(product)" *ngIf="product.has_serials"class="fas fa-barcode ml-2"></i>

    <i (click)="openProduct(product.id)" data-toggle="tooltip" title="Apri scheda" class="text-secondary fas fa-pencil-alt ml-2" style="cursor:pointer;"></i>
    <i *ngIf="product.stocks && product.stocks.length>0 && product.stockable" (click)="openInventories()" data-toggle="tooltip" title="Stato magazzino" class="text-secondary fas fa-boxes ml-2" style="cursor:pointer;"></i>
    <br/><small class="text-info">{{product.category}}</small><br/>
   
    <div *ngIf="showNotes && product && product.state_description!='' && product.state_reference.id!=id_document && product.stockable && isInventoriesEnable" class="badge badge-sm badge-warning">
        <i class="fas fa-truck"></i>&nbsp;{{product.state_description}}
        <ng-container *ngIf="product.state_reference && product.state_reference.id>0">
                <span class="ml-2">
                    | <small>Stato:&nbsp;</small><app-statedocument [state]="product.state_reference.state"></app-statedocument>&nbsp;<b (click)="openDocument(product.state_reference);" style="cursor:pointer;">Dettagli...</b>
                </span>
        </ng-container>
    </div>
</ng-container>


<ng-template #modalDocument let-modal >
    <div class="modal-header bg-light">
        <h5 class="mb-0">Dettagli documento</h5>
    </div>
    <div class="modal-body" *ngIf="document">
        <div class="row">
            <div class="col-5"><label>Documento:</label></div>
            <div class="col-7">
                <div *ngIf="document.typedocument==1" data-toggle="tooltip" title="Fattura Semplice"><span class="badge bg-purple">FA</span> Fattura Semplice</div>
                <div *ngIf="document.typedocument==2" data-toggle="tooltip" title="Fattura Accompagnatoria"  ><span class="badge bg-teal">FA</span> Fattura Accompagnatoria</div>
                <div *ngIf="document.typedocument==3" data-toggle="tooltip" title="Nota di Credito" ><span class="badge bg-pink">NC</span> Nota di Credito</div>
                <div *ngIf="document.typedocument==4" data-toggle="tooltip" title="Preventivo"><span class="badge bg-pink">PR</span> Preventivo</div>
                <div *ngIf="document.typedocument==5" data-toggle="tooltip" title="Documento di trasporto" ><span class="badge badge-secondary">DDT</span> Documento di trasporto</div>
                <div *ngIf="document.typedocument==6" data-toggle="tooltip" title="Scontrino" ><span class="badge badge-secondary">SC</span> Scontrino</div>
                <div *ngIf="document.typedocument==7" data-toggle="tooltip" title="Ordine cliente" ><span class="badge badge-secondary">OC</span> Ordine Cliente</div>
                <div *ngIf="document.typedocument==8" data-toggle="tooltip" title="Ordine fornitore" ><span class="badge badge-secondary">OF</span> Ordine Fornitore</div>
                <div *ngIf="document.typedocument==9" data-toggle="tooltip" title="Non fiscale" ><span class="badge badge-secondary">NF</span> Non fiscale</div>
                <div *ngIf="document.typedocument==10" data-toggle="tooltip" title="Trasferimento" ><span class="badge badge-secondary">TR</span> Trasferimento</div>
                <div *ngIf="document.typedocument==11" data-toggle="tooltip" title="Documento Amministrativo"  ><span class="badge bg-olive">FAc</span> Fattura Acconto</div>
                <div *ngIf="document.typedocument==12" data-toggle="tooltip" title="Fattura Acconto"  ><span class="badge bg-teal">FAc</span> Fattura Acconto</div>
            </div>

            <div class="col-5"><label>Numerazione:</label></div>
            <div class="col-7">{{document.reference}}</div>

            <div class="col-5"><label>Data:</label></div>
            <div class="col-7">{{document.date | date:'dd-MM-yyyy'}}</div>
        
            <div class="col-5"><label>Ultimo aggiornamento:</label></div>
            <div class="col-7"><app-statedocument [state]="document.last_state"></app-statedocument> al {{document.last_state_update | date:'dd-MM-yyyy'}}</div>
        </div>

    </div>


</ng-template>


<ng-template #modalInventories let-modal >
    <div class="modal-header">
        Stato Magazzino
    </div>
    <div class="modal-body" *ngIf="product">
        <table class="table table-sm"> 
            <thead>
                <th>
                    Magazzino
                </th>
                <th>
                    Disponibilità
                </th>
            </thead>
            <tbody>
                <tr *ngFor="let s of product.stocks">
                    <td>{{s.inventoryname}}</td>
                    <td [class.text-success]="s.stock>0" [class.text-danger]="s.stock<0">{{s.stock}} {{product.unit}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td>TOTALE</td>
                    <td [class.text-success]="product.stock>0" [class.text-danger]="product.stock<0"><strong>{{product.stock}} {{product.unit}}</strong></td>
                </tr>
            </tfoot>
        </table>

    </div>

</ng-template>