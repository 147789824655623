import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  
];

routes.push({ path: 'login', loadChildren: () => import('projects/core/src/common/login/login.module').then(m => m['LoginModule']) });
routes.push({ path: 'dashboard-operator', loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule) });
routes.push({ path: 'activities-operator', loadChildren: () => import('./views/activities-operator/activities-operator.module').then(m => m.ActivitiesOperatorModule) });
routes.push({ path: 'inventory', loadChildren: () => import('./views/inventory/inventory.module').then(m => m.InventoryModule) });
routes.push({ path: 'payments', loadChildren: () => import('./views/payments/payments.module').then(m => m.PaymentsModule) });
routes.push({ path: 'updategps', loadChildren: () => import('./views/updategps/updategps.module').then(m => m.UpdategpsModule) });
routes.push({ path: 'installationlog', loadChildren: () => import('./views/installationslogs/installationslogs.module').then(m => m.InstallationslogsModule) });
routes.push({ path: 'installations', loadChildren: () => import('./views/installations/installations.module').then(m => m.InstallationsModule) });

//!!!!!! NECESSARIO PER GARANTIRE LE CORRETTE DIPENDENZE !!!!!!
routes.push({ path: 'admin-booking', loadChildren: () => import('projects/c1-backend/src/app/views/booking/booking.module').then(m => m.BookingModule) });
routes.push({ path: 'admin-address', loadChildren: () => import('projects/c1-backend/src/app/views/address/address.module').then(m => m.AddressModule) });
routes.push({ path: 'admin-project', loadChildren: () => import('projects/c1-backend/src/app/views/projects/projects.module').then(m => m.ProjectsModule) });
routes.push({ path: 'admin-ads', loadChildren: () => import('projects/modules/src/app/ads/ads.module').then(m => m.AdsModule) });
routes.push({ path: 'admin', loadChildren: () => import('projects/c1-backend/src/app/app.module').then(m => m.AppModule) });



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
